import React, { useEffect, useRef, useState } from "react";
import { Grid } from "@mui/material";
import Stage from "./components/Stage";
import PipeLineFastNewItemModal from "./components/FastNewItemModal";
import PropTypes from "prop-types";
import LoadingComponent from "../../hocs/LoadingComponent";
import SoftBox from "../../components/SoftBox";
import SoftSelect from "../../components/SoftSelect";
import SoftInput from "../../components/SoftInput";
import SoftButton from "../../components/SoftButton";
import BidRAPI from "../../API/BidRAPI";

function PipeLine({ pipeline, stageList }) {

  const [loading, setLoading] = useState(true)
  const [pipeLine, setPipeLine] = useState({})

  async function load() {
    const res = await BidRAPI.search()
    setPipeLine(res)
    setLoading(false)
  }

  const emptyNewModalData = {
    open: false,
    pipeline: pipeline,
    stage: ''
  }

  const [newModalData, setNewModalData] = useState(emptyNewModalData)

  function handleOpenNewItem(stage) {
    setNewModalData({
      open: true,
      pipeline: pipeline,
      stage: stage
    })
  }
  function handleCloseNewItem() {
    setNewModalData(emptyNewModalData)
  }

  const [search, setSearch] = useState('')
  const [bufSearch, setBufSearch] = useState('')

  const [managerId, setManagerId] = useState(0)

  const managers = [
    {label: 'Все менеджеры', value: 0},
    {label: 'Дидар', value: 5},
    {label: 'Рустам', value: 7},
    {label: 'Довран', value: 8},
    {label: 'Сердар', value: 9},
    {label: 'Марина', value: 10},
    {label: 'Руслан', value: 11},
    {label: 'Мухаммед', value: 12},
    {label: 'Davlet', value: 16},
    {label: 'Gochmurat', value: 17},
  ]

  async function handleSearch(e) {
    if (e.key === "Enter") {
      setSearch(bufSearch)
    }
  }

  useEffect(() => {
    setLoading(true)
    setSearch('')
    setBufSearch('')
    setManagerId(0)
    load()
  }, [pipeline])
  const ref = useRef(null);
  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <LoadingComponent isLoading={loading} isLoaded={1}>

      <SoftBox display="flex" sx={{mb: 1, maxWidth: '450px', columnGap: '0.5rem'}}>
        <SoftSelect size="small" placeholder="Менеджер" options={managers} value={managers.filter(option => option.value === managerId)} onChange={(option) => setManagerId(option.value)} />
        <SoftInput style={{maxWidth: '150px'}} size="small" placeholder="Поиск..." value={bufSearch} onChange={(e) => setBufSearch(e.target.value)} onKeyPress={handleSearch}/>

        <div style={{display: 'contents', float: 'right'}}>
          <SoftButton size="small" onClick={() => scroll(-200)}>&lt;</SoftButton>
          <SoftButton size="small" onClick={() => scroll(200)}>&gt;</SoftButton>
        </div>
      </SoftBox>
      <div id="pipescroll" style={{display: 'contents'}}>
        <div style={{overflowX: 'auto'}} ref={ref}>
          <Grid container spacing={1} style={{width: '1900px', flexFlow: 'row'}}>
            {stageList.map(stage => <Stage key={stage.name} stage={pipeLine[stage.name] ?? {stage: stage.name, items: [], sum: 0, cnt: 0}} color={stage.color} update={load} handleNewItem={() => handleOpenNewItem('Новый лид'/*stage.name*/)} search={search.toLowerCase()} managerId={managerId}/>)}
          </Grid>
        </div>
      </div>
      <PipeLineFastNewItemModal open={newModalData.open} handleClose={handleCloseNewItem} pipeline={newModalData.pipeline} stage={newModalData.stage}/>
    </LoadingComponent>
  );
}

PipeLine.propTypes = {
  pipeline: PropTypes.string.isRequired,
  stageList: PropTypes.array.isRequired
};
export default PipeLine;
