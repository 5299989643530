import React, {useEffect, useState} from 'react';
import FormCard from "../UI/FormCard";
import TaskTR from "../TaskTR";
import {toast} from "react-toastify";
import Moment from "moment/moment";
import Modal from "../UI/Modal";
import InfoCard from "../UI/InfoCard";
import ObjectRAPI from "../../API/ObjectRAPI";
import AddMaterialModal from "../AddModals/AddMaterialModal";
import AddDeliveryModal from "../AddModals/AddDeliveryModal";
import AddSpecialWorkModal from "../AddModals/AddSpecialWorkModal";

const OrderDetailMain = (props) => {
    const [taskList, setTaskList] = useState([
        {
            title: 'Выездной замер',
            performer: {
                fio: 'Замеров Р.Л.',
                avatar: '/assets/img/team-2.jpg'
            },
            date: 'Сегодня'
        },
        {
            title: 'Дать задачу дизайнеру',
            performer: {
                fio: 'Менеджерова М.М.',
                avatar: '/assets/img/team-1.jpg'
            },
            date: 'Сегодня'
        },
        {
            title: 'Уточнить статус задачи у дизайнера',
            performer: {
                fio: 'Менеджерова М.М.',
                avatar: '/assets/img/team-1.jpg'
            },
            date: '5 марта'
        }
    ])

    const [modalAddTask, setModalAddTask] = useState(false)

    const [taskData, setTaskData] = useState({
        title: '',
        performer: {
            fio: 'Менеджерова М.М.',
            avatar: '/assets/img/team-1.jpg'
        },
        date: ''
    })

    function addTask() {
        if (taskData.title === '') {
            toast.error('Введите название задачи')
        } else if (taskData.date === '') {
            toast.error('Выберите дату задачи')
        } else {
            let newData = taskData
            newData.date = Moment(newData.date).format('D MMMM')
            setTaskList([...taskList, newData]);
            setTaskData({...taskData, 'title': '', 'date': ''})
            setModalAddTask(false)
        }
    }

    function setTD(e) {
        const { name, value } = e.target;

        if (name === 'performer') {
            let performer = {fio: 'Менеджерова М.М.', avatar: '/assets/img/team-1.jpg'}

            switch (value) {
                case '2':
                    performer = {fio: 'Замеров Р.Л.', avatar: '/assets/img/team-2.jpg'}
                    break
            }

            setTaskData({...taskData, [name]: performer});
        } else {
            setTaskData({...taskData, [name]: value});
        }
    }

    const taskFields = [
        {
            title: 'Задача',
            name: 'title',
            type: 'string',
            value: taskData.title,
            setValue: (e) => setTD(e)
        },
        {
            title: 'Исполнитель',
            name: 'performer',
            type: 'select',
            value: taskData.performer.fio,
            setValue: (e) => setTD(e),
            options: [
                {
                    label: 'Менеджерова М.М.',
                    value: '1'
                },
                {
                    label: 'Замеров Р.Л.',
                    value: '2'
                }
            ]
        },
        {
            title: 'Срок',
            name: 'date',
            type: 'date',
            value: taskData.date,
            setValue: (e) => setTD(e)
        },
    ]

    const [incomes, setIncomes] = useState([])

    const [modalAddIncome, setModalAddIncome] = useState(false)

    let emptyIncome = {
        date: '',
        sum: '',
        payer: '',
        contract: '',
        org: '',
    }

    async function addIncome()
    {
        try {
            if (incomeData.date === '') throw Error('Выберите дату')
            if (incomeData.sum === '') throw Error('Введити сумму')
            if (incomeData.payer === '') throw Error('Введити плательщика')
            if (incomeData.contract === '') throw Error('Введити номер договора')
            if (incomeData.org === '') throw Error('Выберите организацию')

            const res = await ObjectRAPI.addIncome(props.order.id, incomeData)
            if (res.error) {
                throw Error(res.error)
            }

            setIncomeData(emptyIncome)
            fetchObjectScreen()
            setModalAddIncome(false)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const [incomeData, setIncomeData] = useState(emptyIncome)

    function setINCD(e) {
        const { name, value } = e.target;
        setIncomeData({...incomeData, [name]: value});
    }

    const incomeFields = [
        {
            title: 'Дата',
            name: 'date',
            type: 'date',
            value: incomeData.date,
            setValue: (e) => setINCD(e)
        },
        {
            title: 'Сумма',
            name: 'sum',
            type: 'number',
            value: incomeData.sum,
            setValue: (e) => setINCD(e)
        },
        {
            title: 'Плательщик',
            name: 'payer',
            type: 'string',
            value: incomeData.payer,
            setValue: (e) => setINCD(e)
        },
        {
            title: 'Договор',
            name: 'contract',
            type: 'string',
            value: incomeData.contract,
            setValue: (e) => setINCD(e)
        },
        {
            title: 'Организация',
            name: 'org',
            type: 'select',
            value: incomeData.org,
            setValue: (e) => setINCD(e),
            options: [
                {
                    label: 'Организация 1',
                    value: '1'
                },
                {
                    label: 'Организация 2',
                    value: '2'
                }
            ]
        },
    ]

    async function fetchObjectScreen() {
        const res = await ObjectRAPI.getIncomes(props.order.id)
        if (res.status) {
            setIncomes(res.result)
        } else {
            toast.error(res.error)
        }
    }

    const [lastInfo, setLastInfo] = useState([])

    async function getLastInfo() {
        const res = await ObjectRAPI.getLastInfo(props.order.id)
        if (res.status) {
            setLastInfo(res.result)
        } else {
            toast.error(res.error)
        }
    }

    function getSumColored(sum) {
        return (
            <span className={sum >= 0?'text-success':'text-danger'}>{Intl.NumberFormat("ru").format(sum)}</span>
        )
    }

    const [modalAddMaterial, setModalAddMaterial] = useState(false)
    const [modalAddDelivery, setModalAddDelivery] = useState(false)
    const [modalAddSpecialWorks, setModalAddSpecialWorks] = useState(false)

    useEffect(() => {
        fetchObjectScreen()
        getLastInfo()
    }, [])

    const [exp1, setExp1] = useState(false)
    const [exp2, setExp2] = useState(false)
    const [exp3, setExp3] = useState(false)
    const [exp4, setExp4] = useState(false)

    return (
        <div className="container-fluid py-4 pe-0 ps-0">
            <div className="row">
                <div className="col-lg-12 col-md-12">
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <InfoCard
                                title="Внутренняя статистика"
                                elements={[
                                    {label: 'Баланс', value: getSumColored(props.order.inner_balance)},
                                    {label: 'Материалы', value: Intl.NumberFormat("ru").format(props.order.inner_materials)},
                                    {label: 'Снабжение', value: Intl.NumberFormat("ru").format(props.order.inner_supply)},
                                    {label: 'Доставка', value: Intl.NumberFormat("ru").format(props.order.inner_delivery)},
                                    {label: 'Спецмонтаж', value: Intl.NumberFormat("ru").format(props.order.inner_special_works)},
                                    {label: 'Аванс', value: Intl.NumberFormat("ru").format(props.order.inner_stages_closed)},
                                    {label: 'Внутренние расходы', value: Intl.NumberFormat("ru").format(props.order.inner_outcomes)},
                                ]}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <InfoCard
                                title="Клиентская статистика"
                                elements={[
                                    {label: 'Баланс', value: getSumColored(props.order.balance)},
                                    {label: 'Поступления', value: Intl.NumberFormat("ru").format(props.order.income)},
                                    {label: 'Расход', value: Intl.NumberFormat("ru").format(props.order.consume)},
                                    {label: 'Стоимость работ', value: Intl.NumberFormat("ru").format(props.order.stages_total)},
                                    {label: 'Общее выполнение', value: Intl.NumberFormat("ru").format(props.order.stages_closed)},
                                    {label: 'Закуплено материалов', value: Intl.NumberFormat("ru").format(props.order.materials)},
                                    {label: 'Спецмонтажные работы', value: Intl.NumberFormat("ru").format(props.order.special_works)}
                                ]}
                            />
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card h-100">
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center"><h5
                                            className=" ms-1 mb-0">Поступления</h5>
                                            <div className="text-end ms-auto">
                                                <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddIncome(true)}>
                                                    <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-1" style={{maxHeight: '265px', overflowX: 'auto'}}>
                                    <table className="table text-center">
                                        <thead>
                                        <tr>
                                            <th style={{padding: '0.5rem'}}>Дата</th>
                                            <th style={{padding: '0.5rem'}}>Сумма</th>
                                            <th style={{padding: '0.5rem'}}>Плательщик</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {incomes.map(el =>
                                            <tr key={el.id}>
                                                <td>{Moment(el.date).format('DD.MM.Y')}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.sum)}</td>
                                                <td>{el.payer}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4 mb-4'+(exp1?' fix-expand':'')} onClick={() => setExp1(false)}>
                            <div className="card h-100" onClick={(e) => e.stopPropagation()}>
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center"><h5
                                            className=" ms-1 mb-0">Материалы</h5>
                                            <div className="text-end ms-auto">
                                                <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddMaterial(true)}>
                                                    <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                                </button>

                                                <button type="button" className="btn btn-sm bg-gradient-secondary mb-0 px-3 ms-2" onClick={() => setExp1(!exp1)}>
                                                    <i className="fas fa-expand f075"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-1 card-scrollable-content">
                                    <table className="table text-center">
                                        <thead>
                                        <tr>
                                            <th style={{padding: '0.5rem'}}>Дата</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость к</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость в</th>
                                            <th style={{padding: '0.5rem'}} className="onexp">Наименование</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lastInfo.materials && lastInfo.materials.map(el =>
                                            <tr key={el.id}>
                                                <td>{Moment(el.date).format('DD.MM.Y')}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price)}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price_inner)}</td>
                                                <td className="onexp">{el.material}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4 mb-4'+(exp2?' fix-expand':'')} onClick={() => setExp2(false)}>
                            <div className="card h-100" onClick={(e) => e.stopPropagation()}>
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center"><h5
                                            className=" ms-1 mb-0">Доставка</h5>
                                            <div className="text-end ms-auto">
                                                <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddDelivery(true)}>
                                                    <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                                </button>

                                                <button type="button" className="btn btn-sm bg-gradient-secondary mb-0 px-3 ms-2" onClick={() => setExp2(!exp2)}>
                                                    <i className="fas fa-expand f075"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-1 card-scrollable-content">
                                    <table className="table text-center">
                                        <thead>
                                        <tr>
                                            <th style={{padding: '0.5rem'}}>Дата</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость к</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость в</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lastInfo.deliveries && lastInfo.deliveries.map(el =>
                                            <tr key={el.id}>
                                                <td>{Moment(el.date).format('DD.MM.Y')}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price)}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price_inner)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4 mb-4'+(exp3?' fix-expand':'')} onClick={() => setExp3(false)}>
                            <div className="card h-100" onClick={(e) => e.stopPropagation()}>
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center"><h5
                                            className=" ms-1 mb-0">СпецМонтаж</h5>
                                            <div className="text-end ms-auto">
                                                <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddSpecialWorks(true)}>
                                                    <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                                </button>

                                                <button type="button" className="btn btn-sm bg-gradient-secondary mb-0 px-3 ms-2" onClick={() => setExp3(!exp3)}>
                                                    <i className="fas fa-expand f075"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-1 card-scrollable-content">
                                    <table className="table text-center">
                                        <thead>
                                        <tr>
                                            <th style={{padding: '0.5rem'}}>Дата</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость к</th>
                                            <th style={{padding: '0.5rem'}}>Стоимость в</th>
                                            <th style={{padding: '0.5rem'}} className="onexp">Наименование</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lastInfo.special_works && lastInfo.special_works.map(el =>
                                            <tr key={el.id}>
                                                <td>{Moment(el.date).format('DD.MM.Y')}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price)}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price_inner)}</td>
                                                <td className="onexp">{el.name}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-4 mb-4'+(exp4?' fix-expand':'')} onClick={() => setExp4(false)}>
                            <div className="card h-100" onClick={(e) => e.stopPropagation()}>
                                <div className="card-header pb-0 p-3">
                                    <div className="row">
                                        <div className="col-md-12 d-flex align-items-center"><h5
                                            className=" ms-1 mb-0">TODO: Авансы</h5>
                                            <div className="text-end ms-auto">
                                                <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddSpecialWorks(true)}>
                                                    <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                                </button>

                                                <button type="button" className="btn btn-sm bg-gradient-secondary mb-0 px-3 ms-2" onClick={() => setExp4(!exp4)}>
                                                    <i className="fas fa-expand f075"/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body p-1 card-scrollable-content">
                                    <table className="table text-center">
                                        <thead>
                                        <tr>
                                            <th style={{padding: '0.5rem'}}>Дата</th>
                                            <th style={{padding: '0.5rem'}}>Сумма</th>
                                            <th style={{padding: '0.5rem'}}>Контрагент</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {lastInfo.special_works && lastInfo.special_works.map(el =>
                                            <tr key={el.id}>
                                                <td>{Moment(el.date).format('DD.MM.Y')}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price)}</td>
                                                <td>{Intl.NumberFormat("ru").format(el.price_inner)}</td>
                                            </tr>
                                        )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mb-4">
                            <div className="card">
                                <div className="card-header pb-0 p-3 d-flex">
                                    <h5 className="font-weight-bolder ps-2">Задачи</h5>
                                    <div className="text-end ms-auto">
                                        <button type="button" className="btn btn-sm bg-gradient-primary compressed-btn mb-0" onClick={() => setModalAddTask(true)}>
                                            <i className="fas fa-plus pe-1" aria-hidden="true"/> Добавить
                                        </button>
                                    </div>
                                </div>
                                <div className="card-body px-0 pt-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table className="table align-items-center mb-0">
                                            <thead>
                                            <tr>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Задача</th>
                                                <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">Исполнитель</th>
                                                <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Срок</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {taskList.map(el =>
                                                <TaskTR key={el.title} title={el.title} perfomer={el.performer} date={el.date}/>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal id="appPayment" title="Добавить задачу" onClose={() => setModalAddTask(false)} show={modalAddTask} footerButtons={<button className="btn bg-gradient-success" onClick={() => addTask()}>Добавить</button>}>
                <FormCard
                    title=""
                    type="empty"
                    elements={taskFields}
                />
            </Modal>
            <Modal id="appIncome" title="Добавить платеж" onClose={() => setModalAddIncome(false)} show={modalAddIncome} footerButtons={<button className="btn bg-gradient-success" onClick={() => addIncome()}>Добавить</button>}>
                <FormCard
                    title=""
                    type="empty"
                    elements={incomeFields}
                />
            </Modal>
            <AddMaterialModal getter={modalAddMaterial} construction={props.order.id} setter={setModalAddMaterial} callback={getLastInfo}/>
            <AddDeliveryModal getter={modalAddDelivery} construction={props.order.id} setter={setModalAddDelivery} callback={getLastInfo}/>
            <AddSpecialWorkModal getter={modalAddSpecialWorks} construction={props.order.id} setter={setModalAddSpecialWorks} callback={getLastInfo}/>
        </div>
    );
};

export default OrderDetailMain;