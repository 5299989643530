import axios from "axios";

export default class ObjectRAPI {
    static async search() {
        return (await axios.get('constructions')).data
    }
    static async getByID(id) {
        return (await axios.get('constructions/'+id)).data
    }

    static async getMaterials(id) {
        return (await axios.get('constructions/'+id+'/materials')).data
    }
    static async getIncomes(id) {
        return (await axios.get('constructions/'+id+'/incomes')).data
    }
    static async getLastInfo(id) {
        return (await axios.get('constructions/'+id+'/last_info')).data
    }

    static async addIncome(construction, params) {
        return (await axios.post('constructions/'+construction+'/incomes', params)).data
    }
    static async addMaterial(construction, params) {
        return (await axios.post('constructions/'+construction+'/materials', params)).data
    }
    static async addDelivery(construction, params) {
        return (await axios.post('constructions/'+construction+'/deliveries', params)).data
    }
    static async addSpecialWork(construction, params) {
        return (await axios.post('constructions/'+construction+'/special_works', params)).data
    }

    static async startStage(construction, stage) {
        return (await axios.post('constructions/'+construction+'/stages/'+stage+'/start')).data
    }
    static async endStage(construction, stage) {
        return (await axios.post('constructions/'+construction+'/stages/'+stage+'/end')).data
    }
    static async addStage(construction, params) {
        return (await axios.post('constructions/'+construction+'/stages/', params)).data
    }
    static async editStage(construction, stage, params) {
        return (await axios.put('constructions/'+construction+'/stages/'+stage, params)).data
    }

    static async getStages(construction) {
        return (await axios.get('constructions/'+construction+'/items')).data
    }
    static async addWork(construction, params) {
        return (await axios.post('constructions/'+construction+'/items/', params)).data
    }
    static async saveWork(construction, item, params) {
        return (await axios.put('constructions/'+construction+'/items/'+item, params)).data
    }
}