import axios from "axios";

export default class BidRAPI {
    static async search() {
        return (await axios.get('bids')).data
    }
    static async getByID(id) {
        return (await axios.get('bids/'+id)).data
    }
    static async add(params) {
        return (await axios.post('bids/', params)).data
    }
    static async save(id, params) {
        return (await axios.put('bids/'+id, params)).data
    }
    static async saveKP(id, KP) {
        return (await axios.post('bids/'+id+'/kp', {
            kp_data: KP
        })).data
    }
}