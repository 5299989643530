import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch  from '@mui/material/Switch';
import { FormControlLabel } from "@mui/material";
import PropTypes from "prop-types";

const BuFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  '& .MuiFormControlLabel-label': {
    color: '#67748e',
    fontWeight: 500
  },
  '&.MuiFormControlLabel-labelPlacementEnd': {
    minHeight: 0,
    marginBottom: 0,
  },
  '& .MuiSwitch-sizeMedium': {
    margin: 0
  }
}));

export function BuSwitchPipe({options}) {
  options = {...options, checked: options.get, onChange: (e) => options.set(e.target.checked)}
  return (
    <BuFormControlLabel control={<Switch {...options}/>} />
  )
}

BuSwitchPipe.propTypes = {
  options: PropTypes.any,
  children: PropTypes.node,
};