import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import axios from "axios";
import {SoftUIControllerProvider} from "./context";

axios.defaults.baseURL = 'https://remont16.endlessmind.space/api/';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common = {'Authorization': `Bearer ${localStorage.getItem('token')}`, 'Accept': 'application/json'}
axios.defaults.withCredentials = true

const root = createRoot(document.getElementById("root"));
root.render(

    <SoftUIControllerProvider>
    <App />
    </SoftUIControllerProvider>
);