import axios from "axios";

export default class StatService {
    static async getMain() {
        // const response = await axios.get('https://endlessmind.space/api2/stat/main')
        // return response.data
        return {
            cashbox: 0,
            received: 0,
            revenue: 0,
            revenueMonth: 0
        }
    }
}