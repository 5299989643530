import React, {useState} from 'react';
import OrderDetailWorksCard from "./WorksCard";
import Modal from "../UI/Modal";
import {toast} from "react-toastify";
import ObjectRAPI from "../../API/ObjectRAPI";

const OrderDetailWorks = (props) => {
    const emptyStage = {
        name: '',
        plan_start: '',
        plan_end: '',
        status: 0
    }

    const emptyStageContragent = {
        contragent: '',
        price: '',
        payed: 0
    }

    const [Stages, setStages] = useState(props.stages)

    const [AddStageModal, setAddStageModal] = useState(false)
    const [TheStage, setTheStage] = useState(emptyStage)
    const [StageContragent, setStageContragent] = useState([emptyStageContragent])

    function changeAgent(idx, id) {
        let items = [...StageContragent];

        items[idx] = {
            ...items[idx],
            contragent: id
        }

        setStageContragent(items);
    }

    function changeAgentPrice(idx, price) {
        let items = [...StageContragent];

        items[idx] = {
            ...items[idx],
            price: price
        }

        setStageContragent(items);
    }

    async function addStage() {
        try {
            if (TheStage.name === '') throw Error('Введите название этапа')
            if (TheStage.plan_start === '') throw Error('Выберите плановую дату начала этапа')
            if (TheStage.plan_end === '') throw Error('Выберите плановую дату окончания этапа')

            let sendData = TheStage
            sendData.contragent = StageContragent

            const res = await ObjectRAPI.addStage(props.construction, sendData)
            if (res.error) {
                throw Error(res.error)
            }

            sendData.items = []

            setStages([...Stages, res.result])
            setTheStage(emptyStage)
            setStageContragent([emptyStageContragent])
            setAddStageModal(false)
            toast.success('Этап создан')
        } catch (error) {
            toast.error(error.message)
        }
    }

    function addNewContragent() {
        setStageContragent([...StageContragent, emptyStageContragent])
    }

    return (
        <div>
            {Stages.map((stage, idx) =>
                <OrderDetailWorksCard stage={stage} idx={idx+1} key={idx+1}/>
            )}
            <button className="btn bg-gradient-primary mb-4 mt-3 px-3 py-2 float-end" type="button" onClick={() => setAddStageModal(true)}>
                <i className="fa fa-plus" aria-hidden="true"/> Добавить этап
            </button>
            <Modal id="AddStageModal" title="Новый этап" onClose={() => setAddStageModal(false)} show={AddStageModal} footerButtons={<button className="btn bg-gradient-success" onClick={() => addStage()}>Добавить</button>}>
                <div>
                    <label>Наименование этапа</label>
                    <input type="text" className="form-control" value={TheStage.name} onChange={(e) => setTheStage({...TheStage, name: e.target.value})}/>
                </div>
                <div>
                    <label>Плановая дата начала этапа</label>
                    <input type="date" className="form-control" value={TheStage.plan_start} onChange={(e) => setTheStage({...TheStage, plan_start: e.target.value})}/>
                </div>
                <div>
                    <label>Плановая дата окончания этапа</label>
                    <input type="date" className="form-control" value={TheStage.plan_end} onChange={(e) => setTheStage({...TheStage, plan_end: e.target.value})}/>
                </div>
                <div>
                    <label>Контрагенты</label>
                    <div className="input-group itac" style={{minWidth: 'auto'}}>
                        <input type="text" disabled={1} className="form-control btlri" value="Контрагент" style={{width: '50%'}}/>
                        <input type="text" disabled={1} className="form-control" value="Сумма"/>
                        <input type="text" disabled={1} className="form-control btrri" value="Оплачено"/>
                    </div>
                    {StageContragent.map((el,idx) =>
                        <div key={idx} className="input-group itac" style={{minWidth: 'auto'}}>
                            <select className="form-control" style={{width: '50%'}} value={el.contragent} onChange={(e) => changeAgent(idx, e.target.value)}>
                                <option value="0">Выбрать контрагента</option>
                                <option value="1">Алексей</option>
                                <option value="2">Булат</option>
                                <option value="3">Демонтаж</option>
                                <option value="4">Ильгиз каменьщик</option>
                                <option value="5">Арсен</option>
                                <option value="6">Костя электрик</option>
                                <option value="7">Булат электрик</option>
                                <option value="8">Сергей штукатур</option>
                                <option value="9">Тимур сантехник</option>
                                <option value="10">Стяжка</option>
                                <option value="11">Марат потолки</option>
                                <option value="12">Дима плиточник</option>
                                <option value="13">Юра маляр</option>
                                <option value="14">ОТКАТ УК</option>
                            </select>
                            <input type="number" className="form-control" value={el.price} onChange={(e) => changeAgentPrice(idx, e.target.value)}/>
                            <input type="disabled" className="form-control" value={el.payed} readOnly/>
                        </div>
                    )}
                    <div className="input-group itac" style={{minWidth: 'auto'}}>
                        <button type="button" className="btn btn-sm bg-gradient-secondary" style={{width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0}} onClick={addNewContragent}>Добавить</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OrderDetailWorks;