import React, {useEffect, useState} from 'react';
import OrderDetailWorksItem from "./WorksItem";
import Modal from "../UI/Modal";
import FormCard from "../UI/FormCard";
import {toast} from "react-toastify";
import ObjectRAPI from "../../API/ObjectRAPI";

const OrderDetailWorksCard = (props) => {
    const [collapse, setCollapse] = useState(true)
    const [modalStage, setModalStage] = useState(false)

    const [TheStage, setTheStage] = useState(props.stage)

    const emptyStageContragent = {
        contragent: '',
        price: '',
        payed: 0
    }

    const [StageContragent, setStageContragent] = useState(TheStage.contragent)

    function changeAgent(idx, id) {
        let items = [...StageContragent];

        items[idx] = {
            ...items[idx],
            contragent: id
        }

        setStageContragent(items);
    }
    function changeAgentPrice(idx, price) {
        let items = [...StageContragent];

        items[idx] = {
            ...items[idx],
            price: price
        }

        setStageContragent(items);
    }

    function addNewContragent() {
        setStageContragent([...StageContragent, emptyStageContragent])
    }

    const [StageStatus, setStageStatus] = useState('play')

    async function setStatus() {
        let res = null
        switch (StageStatus) {
            case 'play':
                res = await ObjectRAPI.startStage(TheStage.construction, TheStage.id)
                break
            case 'stop':
                res = await ObjectRAPI.endStage(TheStage.construction, TheStage.id)
                break
        }
        if (res) {
            if (res.status) {
                switch (StageStatus) {
                    case 'play':
                        setStageStatus('stop')
                        toast.success('Этап начат')
                        break
                    case 'stop':
                        setStageStatus('check')
                        toast.success('Этап окончен')
                        break
                }
            }
        }
    }

    async function saveStage() {
        try {
            if (TheStage.name === '') throw Error('Введите название этапа')
            if (TheStage.plan_start === '') throw Error('Выберите плановую дату начала этапа')
            if (TheStage.plan_end === '') throw Error('Выберите плановую дату окончания этапа')

            let sendData = TheStage
            sendData.contragent = StageContragent

            const res = await ObjectRAPI.editStage(TheStage.construction, TheStage.id, sendData)
            if (res.error) {
                throw Error(res.error)
            }

            setModalStage(false)
            toast.success('Этап сохранен')
        } catch (error) {
            toast.error(error.message)
        }
    }

    const emptyWork = {
        stage: TheStage.id,
        name: '',
        unit_type: '',
        price: 0,
        qty: 1
    }

    const [NewWork, setNewWork] = useState(emptyWork)
    const [AddWorkModal, setAddWorkModal] = useState(false)

    async function addWork() {
        try {
            if (NewWork.name === '') throw Error('Введите название')
            if (NewWork.unit_type === '') throw Error('Выберите единицу измерения')
            if (NewWork.price === '' || parseInt(NewWork.price) == 0) throw Error('Введите стоимость')
            if (NewWork.qty === '') throw Error('Введите кол-во')

            let sendData = NewWork

            const res = await ObjectRAPI.addWork(TheStage.construction, sendData)
            if (res.error) {
                throw Error(res.error)
            }
            setTheStage({...TheStage, items: [...TheStage.items, {...res.result, percent: 0}]})

            setNewWork(emptyWork)
            setAddWorkModal(false)
            toast.success('Работа добавлена')
        } catch (error) {
            toast.error(error.message)
        }
    }

    useEffect(() => {
        TheStage.items.map((el) => {
            if (el.percent !== 100) {
                setCollapse(false)
            }
        })

        if (TheStage.started_at == null) {
            setStageStatus('play')
        } else if (TheStage.ended_at == null) {
            setStageStatus('stop')
        } else {
            setStageStatus('check')
        }
    }, [])

    return (
        <div className="card mt-4">
            <div className="card-body p-3">
                <h6 className="font-weight-bolder">
                    <i className={'fas fa-'+StageStatus} aria-hidden="true" onClick={setStatus}/> ЭТАП №{props.idx} {TheStage.name}

                    <button className="btn bg-gradient-secondary mb-0 px-3 py-2 float-end ms-3" type="button" onClick={e => setCollapse(!collapse)}>
                        <i className={'fa fa-angle-'+(collapse?'down':'up')} aria-hidden="true"/>
                    </button>
                    <button className="btn bg-gradient-info mb-0 px-3 py-2 float-end ms-3" type="button" onClick={e => setModalStage(true)}>
                        <i className="fa fa-gear" aria-hidden="true"/>
                    </button>
                    <button className="btn bg-gradient-primary mb-0 px-3 py-2 float-end" type="button" onClick={() => setAddWorkModal(true)}>
                        <i className="fa fa-plus" aria-hidden="true"/> Добавить
                    </button>
                </h6>

                <div className="works-list mt-3" style={{display: collapse?'none':'block'}}>
                    <div className="input-group itac">
                        <input type="text" disabled={1} className="form-control w-20" value="Виды работ"/>
                        <input type="text" disabled={1} className="form-control" value="Ед. Изм."/>
                        <input type="text" disabled={1} className="form-control" value="Цена"/>
                        <input type="text" disabled={1} className="form-control" value="Кол-во"/>
                        <input type="text" disabled={1} className="form-control" value="Стоимость"/>
                        <input type="text" disabled={1} className="form-control" value="% исполнения"/>
                        <input type="text" disabled={1} className="form-control" value="Исполнение"/>
                    </div>
                    {TheStage.items && TheStage.items.map((item) =>
                        <OrderDetailWorksItem item={item} key={item.id}/>
                    )}
                </div>
            </div>
            <Modal id="AddWorkModal" title="Новая работа" onClose={() => setAddWorkModal(false)} show={AddWorkModal} footerButtons={<button className="btn bg-gradient-success" onClick={() => addWork()}>Добавить</button>}>
                <div>
                    <label>Наименование</label>
                    <input type="text" className="form-control" value={NewWork.name} onChange={(e) => setNewWork({...NewWork, name: e.target.value})}/>
                </div>
                <div>
                    <label>Единица измерения</label>
                    <select className="form-control"value={NewWork.unit_type} onChange={(e) => setNewWork({...NewWork, unit_type: e.target.value})}>
                        <option value="">Выберите</option>
                        <option value="шт.">шт.</option>
                        <option value="м2">м2</option>
                        <option value="компл.">компл.</option>
                        <option value="м.п.">м.п.</option>
                        <option value="м2/м.п.">м2/м.п.</option>
                    </select>
                </div>
                <div>
                    <label>Цена</label>
                    <input type="text" className="form-control" value={NewWork.price} onChange={(e) => setNewWork({...NewWork, price: e.target.value})}/>
                </div>
                <div>
                    <label>Кол-во</label>
                    <input type="number" className="form-control" value={NewWork.qty} onChange={(e) => setNewWork({...NewWork, qty: e.target.value})}/>
                </div>
            </Modal>
            <Modal id="appPayment" title={'ЭТАП №' + props.idx + ' ' + TheStage.name} onClose={() => setModalStage(false)} show={modalStage} footerButtons={<button className="btn bg-gradient-success" onClick={() => saveStage()}>Сохранить</button>}>
                <div>
                    <label>Наименование этапа</label>
                    <input type="text" className="form-control" value={TheStage.name} onChange={(e) => setTheStage({...TheStage, name: e.target.value})}/>
                </div>
                <div>
                    <label>Плановая дата начала этапа</label>
                    <input type="date" className="form-control" value={TheStage.plan_start} onChange={(e) => setTheStage({...TheStage, plan_start: e.target.value})}/>
                </div>
                <div>
                    <label>Плановая дата окончания этапа</label>
                    <input type="date" className="form-control" value={TheStage.plan_end} onChange={(e) => setTheStage({...TheStage, plan_end: e.target.value})}/>
                </div>
                <div>
                    <label>Контрагенты</label>
                    <div className="input-group itac" style={{minWidth: 'auto'}}>
                        <input type="text" disabled={1} className="form-control btlri" value="Контрагент" style={{width: '50%'}}/>
                        <input type="text" disabled={1} className="form-control" value="Сумма"/>
                        <input type="text" disabled={1} className="form-control btrri" value="Оплачено"/>
                    </div>
                    {StageContragent.map((el,idx) =>
                        <div key={idx} className="input-group itac" style={{minWidth: 'auto'}}>
                            <select className="form-control" style={{width: '50%'}} value={el.contragent} onChange={(e) => changeAgent(idx, e.target.value)}>
                                <option value="0">Выбрать контрагента</option>
                                <option value="1">Алексей</option>
                                <option value="2">Булат</option>
                                <option value="3">Демонтаж</option>
                                <option value="4">Ильгиз каменьщик</option>
                                <option value="5">Арсен</option>
                                <option value="6">Костя электрик</option>
                                <option value="7">Булат электрик</option>
                                <option value="8">Сергей штукатур</option>
                                <option value="9">Тимур сантехник</option>
                                <option value="10">Стяжка</option>
                                <option value="11">Марат потолки</option>
                                <option value="12">Дима плиточник</option>
                                <option value="13">Юра маляр</option>
                                <option value="14">ОТКАТ УК</option>
                            </select>
                            <input type="number" className="form-control" value={el.price} onChange={(e) => changeAgentPrice(idx, e.target.value)}/>
                            <input type="disabled" className="form-control" value={el.payed} readOnly/>
                        </div>
                    )}
                    <div className="input-group itac" style={{minWidth: 'auto'}}>
                        <button type="button" className="btn btn-sm bg-gradient-secondary" style={{width: '100%', borderTopLeftRadius: 0, borderTopRightRadius: 0}} onClick={addNewContragent}>Добавить</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default OrderDetailWorksCard;