import React, {useEffect, useState} from 'react';
import {useTitle} from "../hooks/useTitle";
import {useNavigate, useParams} from "react-router-dom";
import ContragentRAPI from "../API/ContragentRAPI";

const ContractorDetail = (props) => {
    const {id} = useParams()

    const [user, setUser] = useState({})

    async function get()
    {
        const res = await ContragentRAPI.userById(id)

        if (res.status) {
            setUser(res.result)

        }
    }

    useEffect(function () {
        get()
    }, [])

    return (
        <div className="col-md-12">
            <h4>{user.name}</h4>

            {"name" in user &&
            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className="card p-3">

                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>ФИО:</span> <span>{user.name}</span></div>
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Дата рождения:</span> <span>{user.birthday}</span>
                        </div>
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Специализация:</span> <span>{user.special}</span>
                        </div>
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Телефон:</span> <span>{user.phone}</span></div>
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Комментарий:</span> <span>{user.comment}</span>
                        </div>
                    </div>
                </div>

                <div className="col-md-6 mb-2">
                    <div className="card p-3">
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Сумма начисления:</span>
                            <span>{user.salary}р</span></div>
                        <div className="d-flex justify-content-between text-md mt-1 mb-0"
                             style={{color: 'rgb(103, 116, 142)'}}><span>Сумма к оплате:</span>
                            <span>{user.salary - user.payed}р</span></div>
                    </div>
                </div>

                <div className="col-md-12 mb-2">
                    <div className="card p-3">
                        <table className="table table-striped">
                            <tr>
                                <th>Объект</th>
                                <th>Этап</th>
                                <th>Сумма</th>
                                <th>Оплачено</th>
                            </tr>
                            {user.objects.map(el =>

                                <tr key={el.id}>
                                <td>{el.stage.construction.name}</td>
                                <td>{el.stage.name}</td>
                                <td>{el.price}</td>
                                <td>{el.payed}</td>
                            </tr>
                        )}
                        </table>
                    </div>
                </div>
            </div>
            }

        </div>
    );
}

export default ContractorDetail;