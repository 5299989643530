import React, {useEffect, useState} from 'react';
import {useFetching} from "../hooks/useFetching";
import OrderService from "../API/OrderService";
import OrderTR from "../mycomponents/OrderTR";
import '../css/OrderTR.module.css';
import {useTitle} from "../hooks/useTitle";
import {NavLink} from "react-router-dom";
import ObjectRAPI from "../API/ObjectRAPI";

const Orders = () => {

    const [orders, setOrders] = useState([])

    const [fetchdata, isLoading, errorData] = useFetching(async () => {
        const res = await ObjectRAPI.search()
        setOrders(res.result)
    })

    useEffect(() => {
        fetchdata()
    }, [])

    useTitle('Объекты')

    return (
        <div className="row">
            {orders && orders.map((order) =>
                <OrderTR order={order}/>
            )}
        </div>
    );
};

export default Orders;