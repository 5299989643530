import React from 'react';

const OrderDetailCalendarPage = () => {
    return (
        <div>
            В разработке
        </div>
    );
};

export default OrderDetailCalendarPage;