import React, {useEffect, useState} from 'react';
import ObjectRAPI from "../../API/ObjectRAPI";

const OrderDetailWorksItem = (props) => {

    const [name, setName] = useState(props.item.name)
    const [type, setType] = useState(props.item.unit_type)
    const [price, setPrice] = useState(props.item.price)
    const [qty, setQTY] = useState(props.item.qty)
    const [percent, setPercent] = useState(props.item.percent)

    function saveItem() {
        const data = {
            id: props.item.id,
            name: name,
            unit_type: type,
            price: price,
            qty: qty,
            percent: percent
        }

        ObjectRAPI.saveWork(props.item.construction, props.item.id, data);
    }

    async function changeHandler(e) {
        let func = e.target.getAttribute("setter")
        let value = e.target.value

        eval(func+'(\''+value+'\')')
    }

    useEffect(() => {
        // saveItem()
        console.log('hmm')
    }, [name, type, price, qty, percent])

    return (
        <div className="input-group itac">
            <input type="text" className="form-control w-20" value={name} onChange={changeHandler} setter="setName"/>
            <select className="form-control" value={type} onChange={changeHandler} setter="setType">
                <option value="шт.">шт.</option>
                <option value="м2">м2</option>
                <option value="компл.">компл.</option>
                <option value="м.п.">м.п.</option>
                <option value="м2/м.п.">м2/м.п.</option>
            </select>
            <input type="number" className="form-control" value={price} onChange={changeHandler} setter="setPrice"/>
            <input type="number" step="0.01" className="form-control" value={qty} onChange={changeHandler} setter="setQTY"/>
            <input type="number" readOnly={true} className="form-control" value={Math.round(price * qty)}/>
            <select className="form-control" value={percent} onChange={changeHandler} setter="setPercent">
                <option value="0">0%</option>
                <option value="10">10%</option>
                <option value="20">20%</option>
                <option value="30">30%</option>
                <option value="40">40%</option>
                <option value="50">50%</option>
                <option value="60">60%</option>
                <option value="70">70%</option>
                <option value="80">80%</option>
                <option value="90">90%</option>
                <option value="100">100%</option>
            </select>
            <input type="number" readOnly={true} className="form-control" value={Math.round(price * qty * (percent / 100))}/>
        </div>
    );
};

export default OrderDetailWorksItem;