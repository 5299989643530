import React, {useState} from 'react';

const Navbar = (props: {toggleSidebar: () => void}) => {

    function toggleTheme () {
        document.body.classList.toggle('darkside');
    }

    return (
        <nav
            className="navbar navbar-main navbar-expand-lg position-sticky mt-4 top-1 px-0 mx-4 border-radius-xl z-index-sticky blur shadow-blur left-auto"
            id="navbarBlur" data-scroll="true">
            <div className="container-fluid py-1 px-3">
                <h5 id="pageName" className="font-weight-bolder mb-0"/>
                <div className="collapse navbar-collapse mt-sm-0 mt-2 me-md-0 me-sm-4" id="navbar">
                    <ul className="ms-md-auto navbar-nav  justify-content-end">
                        <li className="nav-item ps-3 px-3 d-flex align-items-center">
                            <div onClick={props.toggleSidebar} className="nav-link p-0" id="iconNavbarSidenav">
                                <div className="sidenav-toggler-inner">
                                    <i className="sidenav-toggler-line bg-dark"/>
                                    <i className="sidenav-toggler-line bg-dark"/>
                                    <i className="sidenav-toggler-line bg-dark"/>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown pe-2 d-flex align-items-center">
                            <a href="#" className="nav-link p-0" id="dropdownMenuButton"
                               data-bs-toggle="dropdown" aria-expanded="false">
                                <i className="fa fa-bell cursor-pointer" aria-hidden="true"/>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end px-2 py-3 me-sm-n4"
                                aria-labelledby="dropdownMenuButton">
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="#">
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="../../assets/img/team-2.jpg"
                                                     className="avatar avatar-sm  me-3 " alt="user image"/>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New message</span> from
                                                    Laur
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"/>
                                                    13 minutes ago
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li className="mb-2">
                                    <a className="dropdown-item border-radius-md" href="#">
                                        <div className="d-flex py-1">
                                            <div className="my-auto">
                                                <img src="../../assets/img/small-logos/logo-spotify.svg"
                                                     className="avatar avatar-sm bg-gradient-dark  me-3 "
                                                     alt="logo spotify"/>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    <span className="font-weight-bold">New album</span> by Travis
                                                    Scott
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"/>
                                                    1 day
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a className="dropdown-item border-radius-md" href="#">
                                        <div className="d-flex py-1">
                                            <div
                                                className="avatar avatar-sm bg-gradient-secondary  me-3  my-auto">
                                                <svg width="12px" height="12px" viewBox="0 0 43 36" version="1.1"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <title>credit-card</title>
                                                    <g stroke="none" strokeWidth="1" fill="none"
                                                       fillRule="evenodd">
                                                        <g transform="translate(-2169.000000, -745.000000)"
                                                           fill="#FFFFFF" fillRule="nonzero">
                                                            <g transform="translate(1716.000000, 291.000000)">
                                                                <g transform="translate(453.000000, 454.000000)">
                                                                    <path className="color-background"
                                                                          d="M43,10.7482083 L43,3.58333333 C43,1.60354167 41.3964583,0 39.4166667,0 L3.58333333,0 C1.60354167,0 0,1.60354167 0,3.58333333 L0,10.7482083 L43,10.7482083 Z"
                                                                          opacity="0.593633743"/>
                                                                    <path className="color-background"
                                                                          d="M0,16.125 L0,32.25 C0,34.2297917 1.60354167,35.8333333 3.58333333,35.8333333 L39.4166667,35.8333333 C41.3964583,35.8333333 43,34.2297917 43,32.25 L43,16.125 L0,16.125 Z M19.7083333,26.875 L7.16666667,26.875 L7.16666667,23.2916667 L19.7083333,23.2916667 L19.7083333,26.875 Z M35.8333333,26.875 L28.6666667,26.875 L28.6666667,23.2916667 L35.8333333,23.2916667 L35.8333333,26.875 Z"/>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center">
                                                <h6 className="text-sm font-weight-normal mb-1">
                                                    Payment successfully completed
                                                </h6>
                                                <p className="text-xs text-secondary mb-0">
                                                    <i className="fa fa-clock me-1" aria-hidden="true"/>
                                                    2 days
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item px-1 d-flex align-items-center" onClick={toggleTheme}>
                            <svg width="20px" height="20px" viewBox="0 -0.5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.50009 12.0008C7.49604 10.6271 7.87712 9.2798 8.60009 8.11177C9.30106 6.97733 10.3262 6.07929 11.5431 5.53377C12.7306 5.00425 14.0552 4.86422 15.3271 5.13377L15.5091 5.17577C14.7053 5.71232 14.0258 6.41473 13.5161 7.23577C12.7931 8.4038 12.412 9.7511 12.4161 11.1248C12.4068 12.9579 13.0921 14.7265 14.3341 16.0748C15.1848 16.9942 16.285 17.6458 17.5001 17.9498C16.4784 18.6323 15.2778 18.9979 14.0491 19.0008C12.2877 18.9902 10.6101 18.2475 9.41809 16.9508C8.17612 15.6025 7.49083 13.8339 7.50009 12.0008Z" stroke="#ff0080" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;