import PropTypes from "prop-types";
import { Card, Grid } from "@mui/material";
import PipeLineItemModal from "../ItemModal";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import {isToday, isYesterday} from "../../../../hakyda/utils/dates";
import SoftTypography from "../../../../components/SoftTypography";
import {plural} from "../../../../hakyda/utils/plural";

function dateRender(date) {
  if (isToday(date)) {
    return 'Сегодня ' + date.toTimeString().substr(0,5)
  } else if (isYesterday(date)) {
    return 'Вчера ' + date.toTimeString().substr(0,5)
  } else {
    return date.toLocaleDateString()
  }
}

const LIMIT = 20

let FirstUpdate = true

const Stage = ({ stage, color, update, handleNewItem, search, managerId }) => {
  const [open, setOpen] = useState(false)
  const [curItem, setCurItem] = useState(false)

  const [allItems, setAllItems] = useState(filterItems(stage.items))


  const [items, setItems] = useState(allItems.slice(0, LIMIT))
  const [visible, setVisible] = useState(LIMIT)
  const [hasMore, setHasMore] = useState(true)

  useEffect(() => {
    if (!FirstUpdate || search !== '' || managerId !== 0) {
      const bufItems = filterItems(stage.items)

      setAllItems(bufItems)
      setItems(bufItems.slice(0, LIMIT))
      setVisible(LIMIT)
      setHasMore(true)
      FirstUpdate = false
    }
  }, [search, managerId, stage])

  function filterItems(items) {
    return items.filter(el => {
      return (el.address.toLowerCase().includes(search)
        || (el.client.fio != null && el.client.fio.toLowerCase().includes(search))
      ) && (managerId === 0 || el.responsible === managerId)
    })
  }

  function handleClose(needUpdate = true) {
    setOpen(false)
    if (needUpdate) {
      update()
    }
  }

  async function openItem(item) {
    setCurItem(item)
    setOpen(true)
  }

  async function fetchMore() {
    const newLimit = visible + LIMIT
    const addItems = allItems.slice(visible, newLimit)

    if (allItems.length > items.length) {
      setVisible(newLimit)
      setItems([...items].concat(addItems))
    } else {
      setHasMore(false)
    }
  }

  return (
    <Grid item xs={2} style={{maxWidth: '300px'}}>
      <Card sx={{p: 0, mb: 2}} style={{background: 'none', boxShadow: 'none', borderRadius: 0, cursor: 'pointer'}} onClick={handleNewItem}>
        <SoftTypography variant="h6" align="center">{stage.stage}</SoftTypography>
        <SoftTypography variant="caption" align="center">{stage.cnt} {plural(['сделка', 'сделки', 'сделок'], stage.cnt)}: {stage.sum} ₽</SoftTypography>
        <span style={{background: color, height: '3px', marginTop: '0.5rem', marginBottom: '0rem'}}/>
      </Card>
      <div style={{fontFamily: '"Roboto","Helvetica","Arial",sans-serif', fontSize: '0.75rem', fontWeight: '400', lineHeight: '1.5', letterSpacing: '0.03333em'}}>

        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMore}
          hasMore={hasMore}
          loader={<div className="loader" key={0}>Loading ...</div>}
        >
          {items.map(el =>
            <Card sx={{p: 1, mb: 1}} key={el.id} style={{borderRadius: 0, cursor: 'pointer', boxShadow: (el.lastmsg != null ? '0px 0px 0px 1px #f53939 inset' : 'none')}} onClick={() => openItem(el.id)}>
              <div>
                <div style={{float: "left", maxWidth: 'calc(100% - 85px)'}}>{el.client.fio}</div>
                <div style={{float: "right", color: 'rgb(146, 152, 155)'}}>{dateRender(new Date(el.created_at))}</div>
              </div>
              <div style={{clear: 'both'}}>
                <div style={{fontWeight: '500'}}>{el.address}</div>
                <div>{el.responsible}</div>
                <div style={{color: 'rgb(146, 152, 155)'}}>{el.budget} ₽</div>

                <div style={{background: 'rgb(255, 228, 228)', padding: '0 6px', margin: '0 -7px -7px'}}>{el.lastmsg && el.lastmsg.text && el.lastmsg.text.substring(0, 40)}</div>
              </div>
            </Card>
          )}
        </InfiniteScroll>
        <PipeLineItemModal open={open} handleClose={handleClose} itemId={curItem}/>
      </div>
    </Grid>
  );
};

Stage.propTypes = {
  stage: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  update: PropTypes.func.isRequired,
  handleNewItem: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  managerId: PropTypes.number.isRequired
};
export default Stage;