import axios from "axios";

export default class AuthRAPI {
    static async login(phone, password) {
        return (await axios.post('login', {
            phone: phone,
            password: password
        })).data
    }
    static async amIAuth() {
        return (await axios.get('amiauth')).data
    }
    static async logout() {
        return (await axios.post('logout')).data
    }
}