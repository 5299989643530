/* eslint-disable react/prop-types */

import { CircularProgress } from "@mui/material";
import SoftBox from "../components/SoftBox";
import SoftAlert from "../components/SoftAlert";
import SoftTypography from "../components/SoftTypography";

function LoadingComponent({ isLoading, isLoaded, isError, children, ...props }) {

  if (isLoading || !isLoaded) return (
    <SoftBox
      width='100%'
      height="100%"
      display='flex'
      alignItems="center"
      justifyContent='center'
      {...props}
      >
      <CircularProgress />
    </SoftBox>
  )
  if (isError) return (
    <SoftBox
      width='100%'
      height="100%"
      display='flex'
      alignItems="center"
      justifyContent='center'
      {...props}
    >
      <SoftAlert color="error" dismissible>
        <SoftBox display='flex' flexDirection='column'>
          <SoftTypography sx={{whiteSpace: 'normal'}} color="white" variant='h6'>Ошибка.</SoftTypography>
          <SoftTypography sx={{whiteSpace: 'normal'}} color="white" variant='caption'>{`Произошла ошибка при запросе на сервер, код ошибки - ${isError}.`}</SoftTypography>
        </SoftBox>
      </SoftAlert>
    </SoftBox>

  )
  return children
}

export default LoadingComponent;