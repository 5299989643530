import React from 'react';


const myStyle = {
    miniAvatar: {
        width: '32px',
        height: '32px'
    }
}

const TaskTR = (props: {
    title: string,
    perfomer: {
        fio: string,
        avatar: string,
    },
    date: string
}) => {
    return (
        <tr>
            <td>
                <h6 className="mb-0 ps-3 text-sm">{props.title}</h6>
            </td>
            <td>
                <div className="d-flex px-3 py-1">
                    <div>
                        <img src={props.perfomer.avatar}
                             className="avatar me-3" style={myStyle.miniAvatar} alt="avatar imag0e"/>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                        <h6 className="mb-0 text-sm">{props.perfomer.fio}</h6>
                    </div>
                </div>
            </td>
            <td className="align-middle text-center text-sm">
                <p className="text-sm font-weight-bold mb-0">{props.date}</p>
            </td>
        </tr>
    );
};

export default TaskTR;