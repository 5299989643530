import axios from "axios";

export default class ContragentRAPI {
    static async roles() {
        return (await axios.get('contragents/roles')).data
    }
    static async usersByRole(id) {
        return (await axios.get('contragents/roles/'+id)).data
    }
    static async userById(id) {
        return (await axios.get('contragents/'+id)).data
    }
}