import React from 'react';
import {NavLink, useNavigate} from "react-router-dom";

const OrderTR = (props) => {
    return (
        <div className="col-md-3">
            <NavLink to={'/orders/'+props.order.id+'/'} className="card">
                <div className="card-body p-3">
                    <div className="d-flex">
                        <div className="avatar avatar-md bg-gradient-info border-radius-md p-2">
                            <span className="text-lg">{parseInt((props.order.stages_closed / props.order.stages_total) * 100)}%</span>
                        </div>
                        <div className="ms-3 my-auto">
                            <h6 className="mb-0">{props.order.name}</h6>
                            <p className="text-secondary text-sm font-weight-bold">В работе</p>
                        </div>
                        <div className="ms-auto">
                            <div><img src="/assets/img/team-1.jpg" className="avatar" title="Менеджерова М.М." style={{
                                width: "36px",
                                height: "36px"
                            }}/></div>
                        </div>
                    </div>
                    <p className="text-sm mt-1 mb-0" style={{color: "#67748e"}}>Миннахметова Лилия</p>
                    <p className="text-sm" style={{color: "#67748e"}}>{props.order.address} <b>{props.order.square} м²</b></p>
                    <hr className="horizontal dark"/>
                    <div className="row">
                        <div className="col-6">
                            <h6 className={'text-sm mb-0 '+(props.order.balance >= 0?'text-success':'text-danger')}><span className="text-secondary">Баланс:</span><br/> {Intl.NumberFormat("ru").format(props.order.balance)}</h6>
                        </div>
                        <div className="col-6 text-end">
                            <h6 className="text-sm mb-0"><span className="text-secondary">Работ на:</span><br/> {Intl.NumberFormat("ru").format(props.order.stages_total)}</h6>
                        </div>
                    </div>
                </div>
            </NavLink>
        </div>
    );
};

export default OrderTR;