
import PropTypes from "prop-types";
import {
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  List,
  ListItem, ListItemButton, ListItemText,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import SoftTypography from "../../../../components/SoftTypography";
import SoftBox from "../../../../components/SoftBox";
import ItemModalMainTab from "./Tabs/Main";
import ItemModalDocDataTab from "./Tabs/DocData";
import SoftButton from "../../../../components/SoftButton";
import BidRAPI from "../../../../API/BidRAPI";
import BidD2 from "../../../BidD2";


const PipeLineItemModal = ({open, handleClose, itemId}) => {

  const navigate = useNavigate()
  const [tab, setTab] = useState(0)

  const EmptyItem = {
    name: 'Загрузка...',
    id: 'XXXXXXXX',
    stage: 'Загрузка...',
    pipeline: 'Загрузка...',
  }
  const [item, setItem] = useState(EmptyItem)

  async function saveItem(close = true) {
    if (close) handleClose(true)
    const res = await BidRAPI.save(item.id, item)
  }

  async function setItemValue(name, value) {
    let itemCopy = {...item}
    console.log(itemCopy)
    if (name == 'Бюджет ') {
      itemCopy.budget = parseInt(value)
    }
    // if (name == 'Ответственный') {
    //   itemCopy.responsible = parseInt(value)
    // }
    // if (name == 'Рабочий телефон') {
    //   itemCopy.wa_1 = value.replace(/\D/g, '')
    // }
    // if (name == 'Телефон') {
    //   itemCopy.wa_2 = value.replace(/\D/g, '')
    // }
    if (name == 'Название сделки') {
      itemCopy.name = value
    }
    // if (name == 'VIN') {
    //   itemCopy.vin = value
    // }

    itemCopy.rawdata[name] = value

    if (name == 'pipelineAndStage') {
      delete itemCopy.rawdata[name]
      itemCopy.rawdata['Воронка'] = value.pipeline
      itemCopy.pipeline = value.pipeline
      itemCopy.rawdata['Этап сделки'] = value.stage
      itemCopy.stage = value.stage
    }
    setItem(itemCopy)
  }

  function handlerText(event) {
    setItemValue(event.target.name, event.target.value)
  }
  function handlerSwitch(name, value) {
    setItemValue(name, (value ? 'Да' : 'Нет'))
  }
  function handlerSelect(option, element) {
    setItemValue(element.name, option.value)
  }


  async function openItem(itemId) {
    if (itemId) {
      setItem(EmptyItem)
      let res = await BidRAPI.getByID(itemId)
      // let res = await ManagerOrderAPI.getAmoElement(itemId)
      // res = await generateAutoFields(res)
      setItem(res.result)
    }
  }

  useEffect(() => {
    openItem(itemId)
  }, [itemId]);

  const tabFields = [
    [
      {key: 'Ответственный', type: 'select', handler: handlerSelect, options: [
        // {value: 5, label: 'Дидар'},
        // {value: 7, label: 'Рустам'},
        // {value: 8, label: 'Довран'},
        // {value: 9, label: 'Сердар'},
        // {value: 10, label: 'Марина'},
        // {value: 11, label: 'Руслан'},
        // {value: 12, label: 'Мухаммед'},
        // {value: 13, label: 'Арслан'},
      ]},
      {key: 'ФИО', type: 'text', handler: handlerText},
      {key: 'Контактный телефон', type: 'text', handler: handlerText},
      {key: 'Бюджет проекта', type: 'text', handler: handlerText},
      {key: 'Название сделки', type: 'text', handler: handlerText},
      {key: 'Вид объекта', type: 'select', handler: handlerSelect, options: [
        // {value: 5, label: 'Дидар'},
        // {value: 7, label: 'Рустам'},
        // {value: 8, label: 'Довран'},
        // {value: 9, label: 'Сердар'},
        // {value: 10, label: 'Марина'},
        // {value: 11, label: 'Руслан'},
        // {value: 12, label: 'Мухаммед'},
        // {value: 13, label: 'Арслан'},
      ]},
      {key: 'Адрес объекта', type: 'text', handler: handlerText},
      {key: 'Площадь', type: 'text', handler: handlerText},
      {key: 'Нужен дизайн проект', type: 'switch', handler: handlerSwitch},
      {key: 'Комментарий', type: 'text', handler: handlerText},
      {key: 'Источник сделки', type: 'select', handler: handlerSelect, options: [
        // {value: 5, label: 'Дидар'},
        // {value: 7, label: 'Рустам'},
        // {value: 8, label: 'Довран'},
        // {value: 9, label: 'Сердар'},
        // {value: 10, label: 'Марина'},
        // {value: 11, label: 'Руслан'},
        // {value: 12, label: 'Мухаммед'},
        // {value: 13, label: 'Арслан'},
      ]},
    ],
  ]
  const [opens, setOpens] = useState(false);

  const stages1 = [
    {name: 'Новый лид', color: '#99ccff'},
    {name: 'Ждет КП', color: '#ff8f92'},
    {name: 'Согласование КП', color: '#fffd7f'},
    {name: 'Подписание', color: '#99ccff'}
  ]
  const stages2 = [
    {name: 'Повторно', color: '#99ccff'},
    {name: 'Был на КП', color: '#ff8f92'},
    {name: 'Купившие клиенты', color: '#fffd7f'},
    {name: 'Холодная база клиентов', color: '#ffdc7f'},
    {name: 'Нецелевое', color: '#ffdc7f'},
  ]

  async function handleChangeStage(pipeline, stage)
  {
    setItemValue('pipelineAndStage', {pipeline: pipeline, stage: stage})
    setOpens(false)
  }

  function callNum(number)
  {
    // ManagerOrderAPI.needCall(number)
  }

  const docDataFields = {
    money: [
      {key: 'Дата расписки', type: 'text', handler: handlerText},
      {key: 'ФИО', type: 'text', handler: handlerText},
      {key: 'Номер паспорта', type: 'text', handler: handlerText},
      {key: 'Кем выдан паспорт', type: 'text', handler: handlerText},
      {key: 'Дата выдачи паспорта', type: 'text', handler: handlerText},
      {key: 'Сумма расписки', type: 'text', handler: handlerText},
      {key: 'Адрес объекта', type: 'text', handler: handlerText},
    ],
    contract: [
      {key: 'Номер договора логистики', type: 'text', handler: handlerText},
      {key: 'Дата договора логистики', type: 'text', handler: handlerText},
      {key: 'Телефон', type: 'text', handler: handlerText},
      {key: 'Фамилия', type: 'text', handler: handlerText},
      {key: 'Имя', type: 'text', handler: handlerText},
      {key: 'Фамилия EN', type: 'text', handler: handlerText},
      {key: 'Имя EN', type: 'text', handler: handlerText},
      {key: 'Сроки доставки', type: 'text', handler: handlerText},
      {key: 'Номер документа', type: 'text', handler: handlerText},
      {key: 'Гражданство', type: 'text', handler: handlerText},
      {key: 'Страна', type: 'text', handler: handlerText},
      {key: 'Город', type: 'text', handler: handlerText},
      {key: 'Гражданство EN', type: 'text', handler: handlerText},
      {key: 'Страна EN', type: 'text', handler: handlerText},
      {key: 'Город EN', type: 'text', handler: handlerText},
    ],
  }



  const handleDownloadDoc = (doctype) => (event) => {
    event.stopPropagation()
    // Проверка заполненности полей
    if (doctype in docDataFields) {
      let allFields = true
      Object.keys(docDataFields[doctype]).forEach(idx => {
        const key = docDataFields[doctype][idx].key
        if (!item.rawdata[key] || item.rawdata[key] === '') {
          allFields = false
        }
      })

      if (!allFields) {
        alert('Не заполнены необходимые поля документа')
      } else {
        dowloadDoc(doctype)
      }
    } else {
      alert('Нет такого типа документа')
    }
  }

  async function dowloadDoc(doctype) {
    await saveItem(false)
    // ManagerOrderAPI.downloadAmoDoc(item.id, doctype)
  }

  async function generateAutoFields(itemData)
  {
    let raw = itemData.rawdata
    const uslug = itemData.userinfo.slug ?? ''
    const miniId = itemData.id.toString().slice(-4)
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    today = dd + '.' + mm + '.' + yyyy;

    const fields = [
      {field: 'Номер ДКП', value: 'HKD-CTR-24-' + miniId + '-' + uslug},
      {field: 'Дата ДКП', value: today},
      {field: 'Номер договора логистики', value: 'HKD-CTR-L-24-' + miniId + '-' + uslug},
      {field: 'Дата договора логистики', value: today},
      {field: 'Номер инвойса', value: 'HKD-INV-24-' + miniId + '-' + uslug},
      {field: 'Дата инвойса', value: today},
      {field: 'Номер заниженного инвойса', value: 'HKD-ENV-24-' + miniId + '-' + uslug},
      {field: 'Дата заниженного инвойса', value: today},
    ]

    fields.map(f => {
      if (!(f.field in raw) || raw[f.field] === '' || raw[f.field] === null) {
        raw[f.field] = f.value
      }
    })


    return {...itemData, rawdata: raw}
  }

  const [openDup, setOpenDup] = useState(false)

  const [triggerAddStage, setTriggerAddStage] = useState(0)
  const [triggerSave, setTriggerSave] = useState(0)


  return (
    <div style={{display: open?'block':'none', position: 'fixed', width: '100%', background: '#e9ecef', height: '100%', top: 0, left: 0, zIndex: '1200', overflow: 'auto'}}>
      <SoftTypography color="light" variant="h6" style={{position: 'fixed', cursor: 'pointer', top: '0.25rem', left: '0.5rem', zIndex: '1'}} onClick={handleClose}>X</SoftTypography>
      <Grid container style={{height: '100%'}}>

        <Grid item lg={3} md={4} xs={12} bgcolor="#344767" style={{position: 'fixed', width: '25%'}}>
          <SoftBox style={{padding: '1.25rem 2rem 0', color: '#e9ecef'}}>
            <SoftTypography variant="h5" color="light">
              {item.address}
              </SoftTypography>
            <br/><SoftTypography variant="body" color="secondary">#{item.id}</SoftTypography>
            <div onClick={() => setOpens(true)} style={{cursor: 'pointer'}}>
              <SoftTypography variant="body" color="secondary">{item.pipeline}</SoftTypography>
              <br/><SoftTypography variant="body" color="light" fontWeight="bold">{item.stage}</SoftTypography> <SoftTypography variant="body" color="secondary">(XX дней)</SoftTypography>
              <Grid container>
                <Grid item xs={2}>
                  <div style={{background: "lightblue", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
                <Grid item xs={2}>
                  <div style={{background: "red", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
                <Grid item xs={2}>
                  <div style={{background: "orange", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
                <Grid item xs={2}>
                  <div style={{background: "lightblue", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
                <Grid item xs={2}>
                  <div style={{background: "yellow", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
                <Grid item xs={2}>
                  <div style={{background: "#344767", height: '3px', marginTop: '0.25rem', marginBottom: '1rem'}}/>
                </Grid>
              </Grid>
            </div>

            <Dialog
              open={opens}
              onClose={() => setOpens(false)}
              maxWidth="sm"
              fullWidth={true}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <DialogContentText>
                  <Grid container>
                    <Grid item md={6}>

                      <SoftTypography variant="h5">Воронка&nbsp;продаж</SoftTypography>
                      <List>
                        {stages1.map(el =>
                        <ListItem disablePadding key={el.name}>
                          <ListItemButton onClick={() => handleChangeStage('Воронка продаж авто', el.name)}>
                            <ListItemText primary={el.name} />
                          </ListItemButton>
                        </ListItem>
                        )}
                      </List>
                    </Grid>
                    <Grid item md={6}>
                      <SoftTypography variant="h5">Хранилище</SoftTypography>
                      <List>
                        {stages2.map(el =>
                          <ListItem disablePadding key={el.name}>
                            <ListItemButton onClick={() => handleChangeStage('Хранилище', el.name)}>
                              <ListItemText primary={el.name} />
                            </ListItemButton>
                          </ListItem>
                        )}
                      </List>
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
            </Dialog>

          </SoftBox>

          <SoftBox style={{padding: '0rem 1.75rem 0', color: '#e9ecef'}}>

            <AppBar position="static">
              <Tabs
                orientation="horizontal"
                value={tab}
                onChange={(e, newTab) => setTab(newTab)}
                sx={{ background: "transparent" }}
                textColor="inherit"
              >
                <Tab label="Основное"/>
                <Tab label="Документы"/>
                <Tab label="КП"/>
              </Tabs>
            </AppBar>
          </SoftBox>

          <SoftBox style={{padding: '1.25rem 2rem 0', height: 'calc(100vh - 194px)', overflow: 'auto'}} bgColor="white">
            {
              {
                0: <ItemModalMainTab item={item} needKeys={tabFields[0]}/>,
                1: <ItemModalDocDataTab item={item} fields={docDataFields} handleDownloadDoc={handleDownloadDoc}/>,
                2: <>
                  <button className="btn bg-gradient-dark mt-4" onClick={() => setTriggerAddStage((t) => t + 1)}>Добавить этап
                  </button>

                  <button className="btn bg-gradient-primary mt-4 ms-4" onClick={() => setTriggerSave((t) => t + 1)}>Сохранить КП
                  </button>

                </>,
              }[tab]
            }
            <SoftButton color="dark" onClick={() => saveItem(false)} size="small"
                        style={{position: 'fixed', bottom: '1rem', left: '1rem'}}>Сохранить</SoftButton>
          </SoftBox>
        </Grid>
        <Grid item lg={9} md={8} xs={12} style={{marginLeft: '25%'}}>
          {tab === 2 &&
            <BidD2 id={item.id} works={item.kp_data} triggerAddStage={triggerAddStage} triggerSave={triggerSave}/>
          }
        </Grid>
      </Grid>
    </div>
  );
};

PipeLineItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
};
export default PipeLineItemModal;