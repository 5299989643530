import React, {useState} from 'react';
import Modal from "../UI/Modal";
import FormCard from "../UI/FormCard";
import ObjectRAPI from "../../API/ObjectRAPI";
import {toast} from "react-toastify";

const AddMaterialModal = (props) => {

    const title = 'Добавить материал'

    const emptyData = {
        date: '',
        material: '',
        price: '',
        price_inner: '',
        price_supply: '',
        contragent: '',
        info: '',
    }

    async function submitData() {
        try {
            if (FormData.date === '') throw Error('Выберите дату')
            if (FormData.material === '') throw Error('Введите наименование')
            if (FormData.price === '') throw Error('Введите сумму клиентскую')
            if (FormData.price_inner === '') throw Error('Введите сумму внутреннюю')

            const res = await ObjectRAPI.addMaterial(props.construction, FormData)
            if (res.error) {
                throw Error(res.error)
            }

            props.callback()
            setFormData(emptyData)
            props.setter(false)
        } catch (error) {
            toast.error(error.message)
        }
    }

    const [FormData, setFormData] = useState(emptyData)

    function setData(e) {
        const { name, value } = e.target;
        if (name === 'price') {
            setFormData({...FormData, price: value, price_supply: value / 10});
        } else {
            setFormData({...FormData, [name]: value});
        }
    }

    const fields = [
        {
            title: 'Дата',
            name: 'date',
            type: 'date',
            value: FormData.date,
            setValue: (e) => setData(e)
        },
        {
            title: 'Наименование',
            name: 'material',
            type: 'string',
            value: FormData.material,
            setValue: (e) => setData(e)
        },
        {
            title: 'Сумма внутренняя',
            name: 'price_inner',
            type: 'number',
            value: FormData.price_inner,
            setValue: (e) => setData(e)
        },
        {
            title: 'Сумма клиентская',
            name: 'price',
            type: 'number',
            value: FormData.price,
            setValue: (e) => setData(e)
        },
        {
            title: 'Сумма снабжения',
            name: 'price_supply',
            type: 'number',
            value: FormData.price_supply,
            setValue: (e) => setData(e)
        },
        {
            title: 'Комментарий',
            name: 'info',
            type: 'string',
            value: FormData.info,
            setValue: (e) => setData(e)
        },
        {
            title: 'Снабжение',
            name: 'contragent',
            type: 'select',
            value: FormData.contragent,
            setValue: (e) => setData(e),
            options: [
                {
                    label: 'Кто то',
                    value: ''
                },
                {
                    label: 'Макс',
                    value: '1'
                }
            ]
        },
    ]

    return (
        <Modal title={title} onClose={() => props.setter(false)} show={props.getter} footerButtons={<button className="btn bg-gradient-success" onClick={submitData}>Добавить</button>}>
            <FormCard
                title=""
                type="empty"
                elements={fields}
            />
        </Modal>
    );
};

export default AddMaterialModal;