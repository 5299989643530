import React from "react";
import PropTypes from "prop-types";
import SoftSelect from "../../../../../../components/SoftSelect";
import {BuSwitchPipe} from "../../../../../../hakyda/mycomponents/BuSwitchPipe";
import BuInputPileLine from "../../../../../../hakyda/mycomponents/BuInputPipeLine";
import SoftTypography from "../../../../../../components/SoftTypography";
import SoftBox from "../../../../../../components/SoftBox";

const ItemModalMainTab = ({ item, needKeys }) => {
  return (
    <SoftBox>
      {item.rawdata && needKeys.map(el =>
        <SoftBox display="flex" sx={{mb: 1}} key={el.key}>
          <SoftTypography style={{width: '45%'}} variant="button" fontWeight="regular" color="text">
            {el.key}:
          </SoftTypography>
          {
            {
              'text': <BuInputPileLine style={{float: 'right'}} name={el.key} size="small" value={item.rawdata[el.key]} onChange={el.handler}/>,
              'switch': <BuSwitchPipe options={{get: (item.rawdata[el.key] === 'Да' ? 1 : 0), set: (res) =>  el.handler(el.key, res)}}/>,
              'select': <SoftSelect size="small" name={el.key} placeholder={el.key} value={el.options && el.options.filter(option => option.value === parseInt(item.rawdata[el.key]))} onChange={el.handler} options={el.options}/>,
              'selectString': <SoftSelect size="small" name={el.key} placeholder={el.key} value={el.options && el.options.filter(option => option.value === item.rawdata[el.key])} onChange={el.handler} options={el.options}/>
            }[el.type]
          }
        </SoftBox>
      )}
    </SoftBox>
  );
};

ItemModalMainTab.propTypes = {
  item: PropTypes.object.isRequired,
  needKeys: PropTypes.array.isRequired
};
export default ItemModalMainTab;