import React, {useEffect, useState} from 'react';
import FormCard from "../mycomponents/UI/FormCard";
import TaskTR from "../mycomponents/TaskTR";
import Modal from "../mycomponents/UI/Modal";
import {toast} from "react-toastify";
import Moment from "moment";
import {useTitle} from "../hooks/useTitle";
import OrderDetailWorksItem from "../mycomponents/OrderDetail/WorksItem";
import BidRAPI from "../API/BidRAPI";

const BidD2 = (props) => {

    const emptyWorks = [
        {
            name: 'Подготовка объекта',
            works: [
                {
                    name: 'Демонтаж - расписать по позициям',
                    type: 'компл.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Вынос мусора с погрузкой в газель',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Вынос мусора с погрузкой в контейнер',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Укрывка объекта - лифт, подъезд, вх. дверь, окна, радиаторы',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Монтаж временных коммуникаций - вода, свет, канализация',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Возведение перегородок из кирпича, монтаж проемов',
            works: [
                {
                    name: 'Кладка перегородки в 1/2 кирпича (до 100 м2)',
                    type: 'м2',
                    price: 1000,
                    qty: 0
                },
                {
                    name: 'Монтаж/перенос проема в стене/перегородке',
                    type: 'шт.',
                    price: 1000,
                    qty: 0
                }
            ]
        },
        {
            name: 'Черновая подготовка стен',
            works: [
                {
                    name: 'Штукатурка стен/откосов "Под маяк" до 100 м2',
                    type: 'м2/м.п.',
                    price: 350,
                    qty: 0
                },
                {
                    name: 'Формирование угла - 90 градусов',
                    type: 'шт.',
                    price: 600,
                    qty: 0
                },
                {
                    name: 'Штукатурка колонн, арочных, криволинейных участков',
                    type: 'шт.',
                    price: 7500,
                    qty: 0
                },
                {
                    name: 'Сложность - проклейка стен армировочной сеткой',
                    type: 'м2/м.п.',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Сложность - высота стен более 3х метров',
                    type: 'м2/м.п.',
                    price: 350,
                    qty: 0
                }
            ]
        },
        {
            name: 'Электромонтаж (черновой)',
            works: [
                {
                    name: 'Сверление отверстий под подрозетники в штукатурке/киприче/бетоне',
                    type: 'шт.',
                    price: 350,
                    qty: 0
                },
                {
                    name: 'Прокладка силового/слаботочного кабеля без гофры',
                    type: 'м.п.',
                    price: 65,
                    qty: 0
                },
                {
                    name: 'Прокладка силового/слаботочного кабеля в гофре',
                    type: 'м.п.',
                    price: 80,
                    qty: 0
                },
                {
                    name: 'Монтаж распределительной коробки',
                    type: 'шт.',
                    price: 400,
                    qty: 0
                },
                {
                    name: 'Изготовления скрытого кабель канала в стене',
                    type: 'шт.',
                    price: 2000,
                    qty: 0
                },
                {
                    name: 'Штробление под провод в штукатурке/кирпиче/бетоне',
                    type: 'м.п.',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Установка подрозетников в штукатурку',
                    type: 'шт.',
                    price: 450,
                    qty: 0
                },
                {
                    name: 'Монтаж и подключение АВ',
                    type: 'шт.',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Монтаж и подключение УЗО',
                    type: 'шт.',
                    price: 500,
                    qty: 0
                },
                {
                    name: 'Монтаж и подключение РКН',
                    type: 'шт.',
                    price: 1000,
                    qty: 0
                },
                {
                    name: 'Установка электрощита',
                    type: 'шт.',
                    price: 1500,
                    qty: 0
                },
                {
                    name: 'Устройство ниши под электрощит в капитальной стене',
                    type: 'шт.',
                    price: 3000,
                    qty: 0
                },
                {
                    name: 'Прокладка трасс под кондиционеры',
                    type: 'компл.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Монтаж системы приточной вентиляции',
                    type: 'компл.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Монтаж отопления и сантехники (черновой)',
            works: [
                {
                    name: 'Замена/монтаж/перенос радиаторов отопления - дизайн',
                    type: 'шт.',
                    price: 8000,
                    qty: 0
                },
                {
                    name: 'Монтаж узла отопления',
                    type: 'шт.',
                    price: 15000,
                    qty: 0
                },
                {
                    name: 'Опрессовка системы - отопление',
                    type: 'шт.',
                    price: 5000,
                    qty: 0
                },
                {
                    name: 'Устройство точки подключения воды',
                    type: 'шт.',
                    price: 4500,
                    qty: 0
                },
                {
                    name: 'Устройство точки подключения канализации',
                    type: 'шт.',
                    price: 3500,
                    qty: 0
                },
                {
                    name: 'Монтаж водораспределительного узла',
                    type: 'шт.',
                    price: 30000,
                    qty: 0
                },
                {
                    name: 'Установка инсталяции под унитаз',
                    type: 'шт.',
                    price: 8000,
                    qty: 0
                },
                {
                    name: 'Монтаж встраиваемых сантехприборов',
                    type: 'шт.',
                    price: 5500,
                    qty: 0
                },
                {
                    name: 'Монтаж сифона под стиральную, сушильную машины',
                    type: 'шт.',
                    price: 2500,
                    qty: 0
                },
                {
                    name: 'Монтаж сифона под кондиционер',
                    type: 'шт.',
                    price: 3500,
                    qty: 0
                },
                {
                    name: 'Переборка стояков воды/канализации',
                    type: 'шт.',
                    price: 5000,
                    qty: 0
                },
                {
                    name: 'Опрессовка системы - ГВС, ХВС',
                    type: 'шт.',
                    price: 5000,
                    qty: 0
                },
                {
                    name: 'Монтаж и обвязка водонагревателя - бойлер/проточник',
                    type: 'шт.',
                    price: 8000,
                    qty: 0
                },
                {
                    name: 'Установка сервоприводов системы защиты от протечки',
                    type: 'шт.',
                    price: 1000,
                    qty: 0
                },
                {
                    name: 'Шумоизоляция канализационных стояков',
                    type: 'шт.',
                    price: 10000,
                    qty: 0
                },
                {
                    name: 'Устройство штробы/отверстий под трассы',
                    type: 'компл.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Черновая подготовка пола',
            works: [
                {
                    name: 'Устройство механизированной полусухой стяжки пола',
                    type: 'м2',
                    price: 850,
                    qty: 0
                }
            ]
        },
        {
            name: 'Монтаж конструкций из ГКЛ',
            works: [
                {
                    name: 'Монтаж каркаса перегородки',
                    type: 'м2',
                    price: 500,
                    qty: 0
                },
                {
                    name: 'Монтаж каркаса фальшстены',
                    type: 'м2',
                    price: 800,
                    qty: 0
                },
                {
                    name: 'Укладка минваты внутрь каркаса - звукоизоляция',
                    type: 'м2',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Обшивка каркаса листами ГКЛ/ГВЛ - 1 слой',
                    type: 'м2',
                    price: 200,
                    qty: 0
                },
                {
                    name: 'Монтаж листов ГКЛ на клей',
                    type: 'м2',
                    price: 1000,
                    qty: 0
                },
                {
                    name: 'Монтаж оконных откосов из ГКЛ',
                    type: 'м.п.',
                    price: 800,
                    qty: 0
                },
                {
                    name: 'Монтаж дверных откосов из ГКЛ',
                    type: 'м.п.',
                    price: 800,
                    qty: 0
                },
                {
                    name: 'Монтаж примыкания к скрытым дверям',
                    type: 'м.п.',
                    price: 800,
                    qty: 0
                },
                {
                    name: 'Монтаж короба из ГКЛ',
                    type: 'м.п.',
                    price: 1200,
                    qty: 0
                },
                {
                    name: 'Монтаж короба из ГКЛ',
                    type: 'шт.',
                    price: 1800,
                    qty: 0
                },
                {
                    name: 'Монтаж короба из ГКЛ под инсталяцию',
                    type: 'шт.',
                    price: 3200,
                    qty: 0
                },
                {
                    name: 'Монтаж экрана из ГКЛ под ванну',
                    type: 'шт.',
                    price: 3500,
                    qty: 0
                },
                {
                    name: 'Монтаж короба из ГКЛ с нишами',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: 'Монтаж каркаса потолка - одноуровневый',
                    type: 'м2',
                    price: 1200,
                    qty: 0
                },
                {
                    name: 'Монтаж каркаса потолка - двухуровневый',
                    type: 'м2',
                    price: 1500,
                    qty: 0
                },
                {
                    name: 'Обшивка каркаса листами ГКЛ - 1 слой',
                    type: 'м2',
                    price: 250,
                    qty: 0
                },
                {
                    name: 'Монтаж потолочного короба из ГКЛ',
                    type: 'м.п.',
                    price: 1250,
                    qty: 0
                },
                {
                    name: 'Монтаж потолочного короба из ГКЛ',
                    type: 'шт.',
                    price: 1700,
                    qty: 0
                },
                {
                    name: 'Монтаж шторных ниш, ниш под подсветку из ГКЛ',
                    type: 'м.п./шт.',
                    price: 1500,
                    qty: 0
                },
                {
                    name: 'Вырез отверстий в ГКЛ стене под светильники/подрозетники',
                    type: 'шт.',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Вырез отверстий в ГКЛ потолке под светильники/подрозетники',
                    type: 'шт.',
                    price: 200,
                    qty: 0
                },
                {
                    name: 'Монтаж подрозетников в ГКЛ',
                    type: 'шт.',
                    price: 150,
                    qty: 0
                },
                {
                    name: 'Монтаж ревизионного люка',
                    type: 'шт.',
                    price: 2000,
                    qty: 0
                },
                {
                    name: 'Изготовление посадочных мест под встроенные светильники',
                    type: 'м.п./шт.',
                    price: 800,
                    qty: 0
                },
                {
                    name: 'Монтаж закладных (фанера/ОСБ/брус)',
                    type: 'м2/м.п./шт.',
                    price: 350,
                    qty: 0
                },
                {
                    name: 'Фрезеровка ГКЛ',
                    type: 'м.п.',
                    price: 300,
                    qty: 0
                },
                {
                    name: 'Профессиональная звукоизоляции, утепление конструкций',
                    type: 'компл.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Плиточные работы',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Предчистовая подготовка стен, потолка, полов',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Монтаж, нанесение чистовых покрытий, декоративные элементы',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Монтаж натяжных потолков',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Электромонтаж (чистовой)',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Монтаж отопления и сантехники (чистовой)',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        },
        {
            name: 'Прочие работы',
            works: [
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                },
                {
                    name: '',
                    type: 'шт.',
                    price: 0,
                    qty: 0
                }
            ]
        }
    ]

    const [works, setWorks] = useState(props.works ?? emptyWorks)

    function changeHandler(e, stage, work, type) {
        const value = e.target
        let bufWorks = [...works]
        bufWorks[stage].works[work][type] = value.value
        setWorks(bufWorks)
    }

    function deleteHandler(stage, work) {
        let bufWorks = [...works]
        bufWorks[stage].works.splice(work, 1)
        if (bufWorks[stage].works.length === 0) {
            bufWorks.splice(stage, 1)
        }
        setWorks(bufWorks)
    }

    function addHandler(stage) {
        let bufWorks = [...works]
        bufWorks[stage].works.push({
            name: '',
            type: '',
            price: 0,
            qty: 0
        })
        setWorks(bufWorks)
    }

    function addStage() {
        let bufWorks = [...works]
        bufWorks.push({
            name: '',
            works: [
                {
                    name: '',
                    type: '',
                    price: 0,
                    qty: 0
                }
            ]
        })
        setWorks(bufWorks)
    }

    function renameStage(stage, name) {
        let bufWorks = [...works]
        bufWorks[stage].name = name
        setWorks(bufWorks)
    }

    async function save() {
        const res = await BidRAPI.saveKP(props.id, works)
    }

    useEffect(() => {
        if (props.triggerAddStage > 0) addStage()
    }, [props.triggerAddStage]);
    useEffect(() => {
        if (props.triggerSave > 0) save()
    }, [props.triggerSave]);
    return (
        <div style={{margin: '-0.5rem 1rem 0rem'}}>
            <div className="row">
                <div className="col-md-12">
                    {works && works.map((stage, k) =>
                        <div className="card mt-4" key={k}>
                            <div className="card-body p-3">
                                <h6 className="font-weight-bolder">
                                    ЭТАП №{k+1} <input className="form-control ms-3 font-weight-bold" style={{display: 'inline-block', width: '40%'}} value={stage.name} onChange={(e) => renameStage(k, e.target.value)}/>

                                    <button className="btn bg-gradient-primary mb-0 px-3 py-2 float-end" type="button" onClick={() => addHandler(k)}>
                                        <i className="fa fa-plus" aria-hidden="true"/> Добавить
                                    </button>
                                </h6>

                                <div className="works-list mt-3">
                                    <div className="input-group itac">
                                        <input type="text" disabled={1} className="form-control w-20" value="Виды работ"/>
                                        <input type="text" disabled={1} className="form-control" value="Ед. Изм."/>
                                        <input type="text" disabled={1} className="form-control" value="Цена"/>
                                        <input type="text" disabled={1} className="form-control" value="Кол-во"/>
                                        <input type="text" disabled={1} className="form-control" value="Стоимость"/>
                                        <input type="text" disabled={1} className="form-control" value="" style={{width: '45px', maxWidth: '45px'}}/>
                                    </div>
                                    {stage.works && stage.works.map((el, key) =>
                                        <div className="input-group itac" key={key}>
                                            <input type="text" className="form-control w-20" value={el.name} onChange={e => changeHandler(e, k, key, 'name')}/>
                                            <select className="form-control" value={el.type} onChange={e => changeHandler(e, k, key, 'type')}>
                                                <option value="шт.">шт.</option>
                                                <option value="м2">м2</option>
                                                <option value="компл.">компл.</option>
                                                <option value="м.п.">м.п.</option>
                                                <option value="м2/м.п.">м2/м.п.</option>
                                            </select>
                                            <input type="number" className="form-control" value={el.price} onChange={e => changeHandler(e, k, key, 'price')}/>
                                            <input type="number" step="0.01" className="form-control" value={el.qty} onChange={e => changeHandler(e, k, key, 'qty')}/>
                                            <input type="number" readOnly={true} className="form-control" value={el.price * el.qty}/>
                                            <input value={'X'} onClick={() => deleteHandler(k, key)} readOnly={true} className="btn bg-gradient-danger mb-0 p-0" style={{width: '45px'}}/>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <button className="btn bg-gradient-primary mt-4 float-end ms-4" onClick={save}>Сохранить</button>
                    <button className="btn bg-gradient-dark mt-4 float-end" onClick={addStage}>Добавить этап</button>
                </div>
            </div>
        </div>
    );
};

export default BidD2;