import React, {useEffect, useState} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Main from './pages/Main';
import Sidebar from "./mycomponents/Sidebar";
import Orders from "./pages/Orders";
import Navbar from "./mycomponents/Navbar";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import StatService from "./API/StatService";
import ReactInputMask from "react-input-mask";
import OrderDetail from "./pages/OrderDetail";
import AuthRAPI from "./API/AuthRAPI";
import axios from "axios";
 import './css/App.css'
import NewBid from "./pages/NewBid";
import Bids from "./pages/Bids";
import BidDetail from "./pages/BidDetail";
import Contractors from "./pages/Contractors";
import ContractorsList from "./pages/ContractorsList";
import ContractorDetail from "./pages/ConstractorDetail";
import PipeLine from "./pages/PipeLine";
import {ThemeProvider} from "@mui/material";
import theme from "assets/theme";

function App() {

    const [authed, setAuthed] = useState(false)
    const [phone, setPhone] = useState('')
    const [pass, setPass] = useState('')

    const [pinSidebar, setPinSidebar] = useState(false)

    async function logout() {
        const auth = await AuthRAPI.logout()
        setAuthed(!auth.status)
    }
    function togglePinSidebar()
    {
        if (pinSidebar) {
            setPinSidebar(false);
            document.body.classList.remove('g-sidenav-pinned');
        } else {
            setPinSidebar(true);
            document.body.classList.add('g-sidenav-pinned');
        }
    }
    async function amIAuth() {
        const auth = await AuthRAPI.amIAuth()
        setAuthed(auth.status)
    }

    async function auth() {
        const res = await AuthRAPI.login(phone, pass)
        if (res.status) {
            axios.defaults.headers.common = {'Authorization': `Bearer ${res.result.token}`}
            localStorage.setItem('token', res.result.token)
            setAuthed(true)
        } else {
            toast.error(res.error)
        }
    }

    const authEnterPress = async (event) => {
        if (event.key === "Enter") {
            auth()
        }
    }

    useEffect(() => {
        amIAuth()
    }, [])

    if (authed) {
        return (

            <ThemeProvider theme={theme}>
            <BrowserRouter>
                <div className="Main">
                    <Sidebar pin={pinSidebar} logout={() => logout()}/>
                    <main className="main-content position-relative min-height-vh-100 border-radius-lg ">
                        <Navbar toggleSidebar={() => togglePinSidebar()}/>
                        <div className="container-fluid py-4">
                            <Routes>
                                <Route path="/" element={<Main/>}/>
                                <Route path="/bids" element={<PipeLine pipeline={'Воронка'} stageList={[
                                    {name: 'Новый лид', color: '#99ccff'},
                                    {name: 'Ждет КП', color: '#ff8f92'},
                                    {name: 'Согласование КП', color: '#fffd7f'},
                                    {name: 'Подписание', color: '#99ccff'},
                                ]}/>}/>
                                {/*<Route path="/bids" element={<Bids/>}/>*/}
                                {/*<Route path="/bids/new" element={<NewBid/>}/>*/}
                                {/*<Route path="/bids/:id" element={<BidDetail/>}/>*/}
                                <Route path="/orders/:id" element={<OrderDetail/>}/>
                                <Route path="/orders" element={<Orders/>}/>
                                <Route path="/contractors" element={<Contractors/>}/>
                                <Route path="/contractors/roles/:id" element={<ContractorsList/>}/>
                                <Route path="/contractors/:id" element={<ContractorDetail/>}/>
                            </Routes>
                        </div>
                    </main>
                    <ToastContainer autoClose={3000} position="bottom-right"/>
                </div>
            </BrowserRouter>
            </ThemeProvider>
        )
    } else {

        return (
            <main className="main-content  mt-0">
                <section>
                    <div className="page-header min-vh-100">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                                    <div className="card card-plain">
                                        <div className="card-header pb-0 text-start" style={{background: "transparent"}}>
                                            <h4 className="font-weight-bolder">Вход</h4>
                                            <p className="mb-0">Введите номер телефона и пароль <br/>Или воспользуйтесь кодом</p>
                                        </div>
                                        <div className="card-body">
                                            <form role="form">
                                                <div className="mb-3">
                                                    <ReactInputMask mask="+7 (999) 999-99-99" type="text" className="form-control form-control-lg"
                                                                    placeholder="Номер телефона" value={phone}
                                                                    onChange={(e) => setPhone(e.target.value)}
                                                                    onKeyPress={(e) => authEnterPress(e)}
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <input type="password" className="form-control form-control-lg"
                                                           placeholder="Пароль" value={pass}
                                                           onChange={(e) => setPass(e.target.value)}
                                                           onKeyPress={(e) => authEnterPress(e)}
                                                    />
                                                </div>
                                                <div className="text-center">
                                                    <button type="button" onClick={auth}
                                                            className="btn btn-lg bg-gradient-primary btn-lg w-100 mt-4 mb-0">Войти
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                                    <div
                                        className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center">
                                        <img src="/assets/img/shapes/pattern-lines.svg" alt="pattern-lines"
                                             className="position-absolute opacity-4 start-0"/>
                                        <h4 className="mt-5 text-white font-weight-bolder">Remont16</h4>
                                        <p className="text-white">Чем более простым кажется продукт, тем больше
                                            усилий, вложил разработчик в него.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <ToastContainer autoClose={3000} position="bottom-right"/>
            </main>
        )
    }
}

export default App;
