import React from 'react';
import {FormType, FormElementType} from "../../models/FormModels";

const FormCard = (props: FormType) => {
    const renderItem = (item: FormElementType) => {
        switch(item.type) {
            case "select": return <select className="form-control" onChange={item.setValue} name={item.name} value={item.value}>
                    {item.options && item.options.map((option) =>
                        <option key={option.value} value={option.value}>{option.label}</option>
                    )}
                </select>
            case "text": return <textarea className="form-control" onChange={item.setValue} name={item.name} value={item.value}></textarea>
            case "number": return <input className="form-control" type="number" value={item.value} onChange={item.setValue} name={item.name}/>
            case "switch": return <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={item.value} id={'flexSwitchCheck_' + item.name} name={item.name} value="on" onChange={item.setValue}/>
                    <label className="form-check-label" htmlFor={'flexSwitchCheck_' + item.name}>{item.title}</label>
            </div>

            case "date": return <input className="form-control" type="date" value={item.value} onChange={item.setValue} name={item.name}/>
            case "btn": return <button className="btn bg-gradient-primary mt-3" value={item.value} onClick={item.setValue} name={item.name}>{item.title}</button>
            default: return <input className="form-control" type="text" value={item.value} onChange={item.setValue} name={item.name}/>
        }
    }

    const pClass = (title: undefined|string) => {
        switch (title) {
            case 'empty':
                return ''
            default:
                return 'card'
        }
    }

    return (
        <div className={pClass(props.type)}>
            <div className="card-body">
                <h5 className="font-weight-bolder">{props.title}</h5>
                {props.elements.map((item)=>
                    <div key={item.name}>
                        {!(item.type == 'switch' || item.type == 'btn') &&
                            <label>{item.title}</label>
                        }
                        { renderItem(item) }
                    </div>
                )}
            </div>
        </div>
    );
};

export default FormCard;