import PropTypes from "prop-types";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  List,
  ListItem, ListItemButton, ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SoftTypography from "../../../../components/SoftTypography";
import BuInputPileLine from "../../../../hakyda/mycomponents/BuInputPipeLine";
import SoftBox from "../../../../components/SoftBox";
import SoftButton from "../../../../components/SoftButton";

const PipeLineFastNewItemModal = ({open, handleClose, pipeline, stage}) => {

  const [phone, setPhone] = useState('')
  const [name, setName] = useState('')
  const [leadName, setLeadName] = useState('')



  async function create() {
    // const res = await ManagerOrderAPI.newAmoElement(phone, name, leadName, pipeline, stage)
    handleClose()
  }

  useEffect(() => {
    if (open) {
      setPhone('')
      setName('')
      setLeadName('')
    }
  }, [open])

  return (
            <Dialog
              open={open}
              onClose={handleClose}
              maxWidth="xs"
              fullWidth={true}
              aria-labelledby="responsive-dialog-title"
            >
              <DialogContent>
                <DialogContentText>

                  <SoftTypography variant="h5" sx={{mb: 2}} color="text">
                    {pipeline} - {stage}
                  </SoftTypography>
                  <SoftBox display="flex" sx={{mb: 1}}>
                    <SoftTypography style={{width: '45%'}} variant="button" fontWeight="regular" color="text">
                      Телефон клиента:
                    </SoftTypography>
                    <BuInputPileLine style={{float: 'right'}} size="small" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                  </SoftBox>
                  <SoftBox display="flex" sx={{mb: 1}}>
                    <SoftTypography style={{width: '45%'}} variant="button" fontWeight="regular" color="text">
                      Имя клиента:
                    </SoftTypography>
                    <BuInputPileLine style={{float: 'right'}} size="small" value={name} onChange={(e) => setName(e.target.value)}/>
                  </SoftBox>
                  <SoftBox display="flex" sx={{mb: 1}}>
                    <SoftTypography style={{width: '45%'}} variant="button" fontWeight="regular" color="text">
                      Название сделки:
                    </SoftTypography>
                    <BuInputPileLine style={{float: 'right'}} size="small" value={leadName} onChange={(e) => setLeadName(e.target.value)}/>
                  </SoftBox>
                  <SoftButton variant="gradient" color="secondary" style={{float: 'right', marginLeft: '1rem'}} onClick={handleClose}>Закрыть</SoftButton>
                  <SoftButton variant="gradient" color="success" style={{float: 'right'}} onClick={create}>Создать</SoftButton>
                </DialogContentText>
              </DialogContent>
            </Dialog>
  );
};

PipeLineFastNewItemModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  pipeline: PropTypes.string.isRequired,
  stage: PropTypes.string.isRequired,
};
export default PipeLineFastNewItemModal;