import axios from "axios"

interface newPaymentType {
    order_id?: number
    amount: number,
    type: number,
    comment?: string,
    is_sale?: number
}
interface PaymentType extends newPaymentType {
    id: number,
    date: string
}

export default class PaymentService {
    static async getToday() {
        const response = await axios.get('https://endlessmind.space/api2/payments')
        return response.data
    }
    static async newPayment(payment: newPaymentType) {
        const response = await axios.post('https://endlessmind.space/api2/payments', payment)
        return response.data
    }
}