import React, {useEffect, useState} from 'react';
import {useTitle} from "../hooks/useTitle";
import {useNavigate} from "react-router-dom";
import ContragentRAPI from "../API/ContragentRAPI";

const Contractors = () => {

    const navigate = useNavigate()

    useTitle('Контрагенты')

    const [roles, setRoles] = useState([])

    async function getRoles()
    {
        const res = await ContragentRAPI.roles()

        if (res.status) {
            setRoles(res.result)
        }
    }

    useEffect(function () {
        getRoles()
    }, [])

    return (
        <div className="col-md-12">
            <div className="row ">
                {roles.map(el =>
                <div className="col-md-3 mb-4 pt-2 pb-2" onClick={() => navigate('/contractors/roles/' + el.id)}>
                    <div className="card" style={{cursor: 'pointer'}}>
                        <div className="card-body p-3">
                            <h6 className="text-center">{el.name}</h6>
                        </div>
                    </div>
                </div>
                )}
            </div>
        </div>
    );
}

export default Contractors;