import React, {useState, useEffect} from 'react';
import {useTitle} from "../hooks/useTitle";
import {useNavigate, useParams} from "react-router-dom";
import ContragentRAPI from "../API/ContragentRAPI";

const ContractorsList = () => {
    const navigate = useNavigate()

    const {id} = useParams()

    useTitle('Офисные сотрудники')

    const [users, setUsers] = useState([])

    async function getList()
    {
        const res = await ContragentRAPI.usersByRole(id)

        if (res.status) {
            setUsers(res.result)
        }
    }

    useEffect(function () {
        getList()
    }, [])

    return (
        <div className="col-md-12">
            <div className="card mt-5 p-4" >
                {users.map(el =>
                    <div onClick={()=> navigate('/contractors/' + el.id)} className="d-flex justify-content-between text-md mt-1 mb-1 cursor-pointer">
                        <span>{el.name}</span>
                        <span>{el.birthday}</span>
                        <span>{el.special}</span>
                    </div>
                )}
            </div>


        </div>
    );
}

export default ContractorsList;