import React, {useEffect, useState} from 'react';
import StatCard from "../mycomponents/UI/StatCard";
import {useNavigate} from "react-router-dom";
import {useFetching} from "../hooks/useFetching";
import StatService from "../API/StatService";
import PaymentService from "../API/PaymentService";
import Modal from "../mycomponents/UI/Modal";
import Moment from 'moment';
import PaymentAdd from "../mycomponents/PaymentAdd";
import {useTitle} from "../hooks/useTitle";

const Main = () => {
    const navigate = useNavigate();
    Moment.locale('ru')

    const [stat, setStat] = useState({
        cashbox: 0,
        received: 0,
        revenue: 0,
        revenueMonth: 0,
    })

    const [fetchStatMain, isLoading, errorData] = useFetching(async () => {
        const res = await StatService.getMain()
        setStat(res)
    })

    const [modal, setModal] = useState(false)
    const [modalPaymentAdd, setModalPaymentAdd] = useState(false)

    const [payments, setPayments] = useState([])

    const [fetchPayments] = useFetching(async () => {
        const res = await PaymentService.getToday()
        setModal(true)
        setPayments(res)
    })

    function typeName(type) {
        switch (type) {
            case 1: return 'Наличные'
            case 2: return 'Терминал (эквайринг)'
            case 3: return 'Перевод на карту'
            case 4: return 'Счет'
            case 5: return 'Бикмухаметов'
            case 6: return 'Рекламный бартер'
            default: return 'Обратитесь к администратору'
        }
    }

    useEffect(() => {
        fetchStatMain()
    }, [])
    useTitle('Главная')

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="row" style={{rowGap: '25px'}}>
                    <div className="col-sm-6 col-lg-3">
                        <StatCard
                            clickable={true}
                            onClick={() => navigate('/orders')}
                            clickableIcon={true}
                            onClickIcon={() =>  navigate('/orders')}
                            icon="ni-fat-add"
                            title="Объекты"
                            value="Перейти"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Main;