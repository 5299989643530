import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SoftBox from "../../../../../../components/SoftBox";
import SoftTypography from "../../../../../../components/SoftTypography";
import {BuSwitchPipe} from "../../../../../../hakyda/mycomponents/BuSwitchPipe";
import BuInputPileLine from "../../../../../../hakyda/mycomponents/BuInputPipeLine";
import SoftSelect from "../../../../../../components/SoftSelect";
import SoftButton from "../../../../../../components/SoftButton";

const ItemModalDocDataTab = ({ item, fields, handleDownloadDoc }) => {

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordions = [
    {title: 'Договор', doctype: 'contract'},
    {title: 'Расписка', doctype: 'money'},
  ]

  return (
    <SoftBox style={{margin: '-1.25rem -2rem'}}>
      {accordions.map(accord =>
      <Accordion key={accord.title} expanded={expanded === accord.doctype} onChange={handleChange(accord.doctype)}>
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div style={{display: 'flex', placeContent: 'space-between', width: '100%'}}>
            <SoftTypography variant="h6" fontWeight="regular">
              {accord.title}
            </SoftTypography>
            <SoftButton variant="gradient" color="dark" size="small" onClick={handleDownloadDoc(accord.doctype)}>Скачать</SoftButton>
          </div>
        </AccordionSummary>
        <AccordionDetails>

          {item.rawdata && fields[accord.doctype].map(el =>
            <SoftBox display="flex" sx={{mb: 1}} key={el.key}>
              <SoftTypography style={{width: '45%'}} variant="button" fontWeight="regular" color="text">
                {el.key}:
              </SoftTypography>
              {
                {
                  'text': <BuInputPileLine style={{float: 'right'}} name={el.key} size="small" value={item.rawdata[el.key]} onChange={el.handler}/>,
                  'switch': <BuSwitchPipe options={{get: (item.rawdata[el.key] === 'Да' ? 1 : 0), set: (res) =>  el.handler(el.key, res)}}/>,
                  'select': <SoftSelect size="small" name={el.key} placeholder={el.key} value={el.options && el.options.filter(option => option.value === parseInt(item.rawdata[el.key]))} onChange={el.handler} options={el.options}/>,
                  'selectString': <SoftSelect size="small" name={el.key} placeholder={el.key} value={el.options && el.options.filter(option => option.value === item.rawdata[el.key])} onChange={el.handler} options={el.options}/>
                }[el.type]
              }
            </SoftBox>
          )}

        </AccordionDetails>
      </Accordion>
      )}
    </SoftBox>
  );
};

ItemModalDocDataTab.propTypes = {
  item: PropTypes.object.isRequired,
  fields: PropTypes.object.isRequired,
  handleDownloadDoc: PropTypes.func.isRequired,
};
export default ItemModalDocDataTab;